@import "../../../../styles/variables.scss";

.menu-item {
  display: inline-flex;
  align-items: center;
  color: $grey;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  position: relative;

  svg {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
    stroke: $grey;
    fill: none;
    path,
    circle {
      stroke: $grey;
    }
  }

  span {
    text-transform: uppercase;
    font-family: Montserrat-Bold;
    font-size: 0.75rem;
    letter-spacing: 0.74px;
    margin-left: 10px;
  }

  &:hover {
    color: $dark;
    cursor: pointer;

    svg {
      stroke: $dark;
      path,
      circle {
        stroke: $dark;
      }
    }
  }

  &.selected,
  &.selected:hover {
    color: $tealish;

    svg {
      stroke: $tealish;
      path,
      circle {
        stroke: $tealish;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -21px;
      height: 3px;
      border-radius: 2px;
      background-image: linear-gradient(268deg, #06038d, #06038d);
    }
  }
}

.menu-item-with-sublinks {
  position: relative;
}

.menu-item-with-sublinks__sublinks {
  position: absolute;
  background-color: $white;
  top: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.29);
  z-index: 110;
  padding: 15px;
  display: flex;
  flex-direction: column;

  > div,
  a {
    font-family: Montserrat-Regular;
    font-size: 0.94rem;
    color: $rating-grey;
    line-height: 2.87;
    white-space: nowrap;
    position: relative;
    cursor: pointer;

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      left: -2px;
      right: -2px;
      bottom: -0.5px;
      height: 1px;
      background-color: $light-grey20;
    }
  }

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid $white;
    position: absolute;
    top: -12px;
    left: 20px;
  }
}

.menu-desktop-nav-iframe {
  .menu-item {
    &:hover {
      svg path {
        stroke: #ee7c56;
      }
      svg circle {
        stroke: #ee7c56;
      }
      &.selected {
        svg path {
          stroke: #ee7c56;
        }
        svg circle {
          stroke: #ee7c56;
        }
      }
    }
    svg path {
      stroke: white;
    }
    svg circle {
      stroke: white;
    }
    &.selected {
      svg path {
        stroke: #ee7c56;
      }
      svg circle {
        stroke: #ee7c56;
      }
    }
  }
}

.menu-item-with-sublinks__sublinks-iframe {
  position: relative;
  top: 0;
  &:after {
    content: none;
  }
  border-radius: 0;
  box-shadow: none;
  a {
    font-size: 0.7em;
  }
}

.menu-desktop-iframe {
  .menu-item {
    span {
      font-size: 0.8em;
      text-transform: capitalize;
    }
    width: 100%;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;

    &.selected {
      background-color: white;
      color: #ee7c56;
      &:after {
        content: none;
      }
      &:hover {
        background-color: white;
        color: #ee7c56;
        &:after {
          content: none;
        }
      }
    }

    &:hover {
      background-color: white;
      color: #ee7c56;
      &:after {
        content: none;
      }
    }
  }
}
