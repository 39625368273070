@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.desktopContainer {
  min-width: 600px;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 4px;
  border: solid 1px $very-light-blue;
  background-color: $white;
}

.desktopContentContainer {
  padding: 30px;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-family: Montserrat-Bold;
      font-size: 1.25rem;
      letter-spacing: 0.2px;
      display: inline-block;
    }
  }
}

.emailInput {
  flex-grow: 1;

  @include mobile {
    margin-left: 15px;
  }

  &.addEmail {
    flex-grow: unset;
    width: 75%;
    margin-right: 25px;

    input {
      padding: 10px 14px;
    }

    &:not(:focus-within) label {
      top: -7px;
    }
  }
}
