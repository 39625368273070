@import "../../../styles/variables.scss";

.container {
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: lowercase;
  font-size: 0.94rem;
  color: $white;
  background-color: $tealish;
}
