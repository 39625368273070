@import "styles/variables.scss";

.outerContainer {
  position: relative;
}

.container {
  max-height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px 0 10px 0;
  overflow-y: scroll;
}

.header {
  font-family: Montserrat-Bold;
  font-size: 1.69rem;
  text-align: center;
  padding: 0 55px 20px 55px;
  background-color: $white;
  z-index: 1;
}

.bodyContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body {
  flex-grow: 1;
  min-height: fit-content;
}

.closeButton {
  position: absolute;
  right: 0;
  top: -32px;
}
