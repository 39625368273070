@import "styles/variables.scss";
@import "styles/mixins.scss";

.grey {
  color: grey;
}

.black {
  color: black;
}

.desktopContainer {
  min-width: 600px;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 4px;
  border: solid 1px $very-light-blue;
  background-color: $white;
  padding-bottom: 70px;
}

.desktopContentContainer {
  padding: 30px;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-family: Montserrat-Bold;
      font-size: 1.25rem;
      letter-spacing: 0.2px;
      display: inline-block;
    }
  }
}

.preview {
  font-family: Montserrat-Bold;
  font-size: 12px;
  letter-spacing: 0.86px;
  text-transform: uppercase;
  color: $blush-pink;
  cursor: pointer;
  align-items: center;

  a,
  .green {
    color: $blush-pink;
  }

  svg {
    margin: 0 10px;
  }
}

.copy {
  font-size: 0.6em;
  color: white;
  background-color: $blush-pink;
  border-radius: 4px;
  padding: 3px 6px;
  margin-left: 5px;
  display: inline-block;
  border: 1px solid $blush-pink;
  vertical-align: top;

  &:hover {
    color: $blush-pink;
    background-color: white;
  }

  @include mobile {
    font-size: 0.8em;
  }
}

svg.eyeLeft {
  margin-left: 0;
  vertical-align: middle;
}

.middleGreen {
  color: $blush-pink;
  display: inline-block;
  vertical-align: middle;
}

.bigLink {
  max-width: 200px;
  display: inline-block;
  color: $blush-pink;
}

span.smallLink {
  text-transform: none;
  font-size: 0.7em;
  color: $blush-pink;
}

.inSalonExplain {
  font-size: 0.9em;
  @include desktop {
    max-width: 75%;
  }
}

.icon {
  margin-right: 5px;
}
