@import "styles/variables.scss";
@import "styles/mixins.scss";

.container {
  background-color: $white;

  @include mobile {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    padding: 25px 15px;
  }

  @include desktop {
    padding: 30px;
    padding-top: 0;
    max-width: 460px;
  }
}

.priceReduced {
  margin-left: 5px;
  font-family: Montserrat-Bold;
  color: $blush-pink;
  font-size: 1rem;
}

.detailsList {
  .priceReduced {
    margin-left: 0;
  }
}

%rowStyle {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.priceRow {
  @extend %rowStyle;
  padding-top: 10px;
}

.descriptionRow {
  @extend %rowStyle;
  font-size: 0.88rem;
  line-height: 1.43;
  color: $bluey-grey;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    bottom: 0;
    background-color: $very-light-blue;
  }
}

%titleStyle {
  font-family: Montserrat-Bold;
  font-size: 1.25rem;
}

.clientName {
  @extend %titleStyle;
}

.details {
  @extend %titleStyle;
  margin-top: 20px;
}

.priceFull {
  font-family: Montserrat-Bold;
  font-size: 0.88rem;
  color: $light-blue-grey;
  text-decoration: line-through;
}

.infoMessage {
  padding-top: 15px;
  font-size: 0.88rem;
  line-height: 1.36;
  letter-spacing: 0.14px;
  text-align: center;
  color: $bluey-grey;
  max-width: 90%;
  margin: auto;
}
