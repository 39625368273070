.hairdresser-rating-preview-extra {
  font-size: 0.813rem;
  color: $bluey-grey;
}

.rating-stars-group {
  min-width: 75px;
}

.rating-salon-tab-container {
  @include mobile {
    padding-top: 5px;
  }

  @include desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: auto;
    height: calc(100% - 30px);
    overflow-y: scroll;
  }
}

.rating-reply-submit-text {
  font-family: Montserrat-Bold;
  font-size: 0.88rem;
  letter-spacing: 1px;
  color: $white;
}

.rating-reply-submit-text.disabled {
  opacity: 0.6;
}

.rating-reply-body-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rating-reply-comment-wrapper {
  padding: 25px 15px;
}

div.rating-reply-input {
  padding: 20px 15px;
  width: 100%;
}

div.rating-reply-input textarea {
  caret-color: $tealish;
}

.autocomplete-input_container {
  width: 100%;
}

.autocomplete-input input {
  color: $white;
  caret-color: $white;
}

.autocomplete-input input::placeholder {
  opacity: 0.6;
  font-size: 1rem;
  color: $white;
  padding-left: 5px;
}

div.autocomplete-input_right-container {
  top: unset;
  bottom: 0;
}

.infinite-scroll-sensor {
  min-height: 5px;
}

.infinite-scroll-loader {
  display: block;
  margin: auto;
  margin-bottom: 5px;
}
