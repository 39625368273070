@import "../../../styles/variables.scss";

.listContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.813rem;
}

.itemContainer {
  padding: 10px 5px 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $very-light-blue;
  }
}

.label {
  letter-spacing: 0.1px;
}

.value {
  color: $bluey-grey;
  letter-spacing: 0.13px;
  /* Text selection */
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
