.page-list_container-mobile {
  margin-top: 15px !important;
}

.page-list_container-mobile .with-subtitle {
  padding-bottom: 3px;
}

.page-list_item_container-mobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.page-list_item_container-mobile::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 0;
  border: solid 0.5px $very-light-blue;

  @include desktop {
    right: 15px;
  }
}

.page-list_item_container-mobile.no-underline::after {
  border-width: 0;
}

.page-list_item-text_container-mobile {
  display: flex;
  flex-direction: column;
}

.page-list_item_title-mobile {
  font-family: Montserrat-Bold;
  text-transform: uppercase;
  color: $dark;
  letter-spacing: 0.8px;
  font-size: 0.813rem;
  opacity: 0.8;
}

li + .page-list_item_subtitle-mobile {
  padding-bottom: 5px;
}

.page-list_item_subtitle-mobile {
  font-size: 0.983rem;
  color: $bluey-grey;
  line-height: 2;
  letter-spacing: 0.1px;
}
