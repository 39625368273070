@import "../../../styles/variables.scss";

button.button-desktop {
  // Reset / Normalize
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  // Styling
  font-size: 0.88rem;
  color: $button-grey;
  padding: 12px 15px 9px 15px;
  border: 1px solid $button-grey60;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: $white;

  svg {
    margin-right: 10px;
  }

  &.button-desktop--dense {
    padding: 3px 10px 4px 10px;
  }

  &.button-desktop--primary {
    color: $white;
    margin: 1px;
    border: none;
    background-image: linear-gradient(259deg, $tealish, $tealish);

    &:focus {
      outline-color: $tealish;
    }
  }

  &.button-desktop--secondary {
    color: $white;
    margin: 1px;
    border: none;
    background-image: linear-gradient(259deg, $blush-pink, $blush-pink);

    &:focus {
      outline-color: $blush-pink;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 5px -1px $button-grey60;

    &:active {
      box-shadow: 1px 1px 1px -1px $button-grey60;
    }
  }

  &:focus {
    outline-color: $button-grey60;
  }
}
