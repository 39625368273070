$logo-color: #24bea1;
$tealish: #06038d;
$grey: #a8a9ae;
$grey20: #a8a9ae33;
$grey80: #a8a9aecc;
$border-grey: #9797974d;
$button-grey: #7d7e86;
$button-grey60: #7d7e8699;
$bluey-grey: #929299;
$light-grey: #979797;
$light-grey20: #97979733;
$rating-grey: #4b4b4b;
$grey-50: #ababab;
$rating-extra-grey: #434343;
$rating-light-grey: #7d7d7d;
$yellow: #ffd200;
$very-light-blue: #e7ebef;
$very-light-blue20: #e7ebef33;
$very-light-blue30: #e7ebef4d;
$very-light-blue70: #e7ebefb3;
$very-light-grey: #f6f8fc;
$light-blue-grey: #c9cacc;
$dark: #252734;
$blush-pink: #f87c56;
$white: #ffffff;
$white80: #ffffffcc;
$white50: #ffffff80;
$white20: #ffffff33;
$warning-red: #f43333;

$black: #000000;
$black50: #00000080;

$backgroud-gradient: linear-gradient(264deg, #06038d, #06038d);

$dark80: #252734cc;
$dark60: #25273499;
$dark50: #25273480;
$dark30: #2527344d;
$dark20: #25273433;

$desktop-menu-height: 70px;

:export {
  tealish: $tealish;
  blueyGrey: $bluey-grey;
  veryLightBlue: $very-light-blue;
  veryLightGrey: $very-light-grey;
  lightBlueGrey: $light-blue-grey;
  dark: $dark;
  blushPink: $blush-pink;
  white: $white;
  backgroudGradient: $backgroud-gradient;
}

$breakpoint-mobile: 690px;
