@import "../../../styles/variables.scss";

/*
** Mobile and Desktop
*/

.autocomplete-select {
  .react-select__control {
    border-left: unset;
    border-top: unset;
    border-right: unset;
    box-shadow: unset;
    border-radius: unset;
  }

  .react-select__value-container {
    padding-left: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    z-index: 102;
  }
}

/*
** Desktop Only
*/

.menu-desktop-salon-selector .autocomplete-select {
  .react-select__control {
    border-bottom: unset;

    &:hover {
      cursor: pointer;
    }
  }

  .react-select__single-value,
  .react-select__placeholder {
    color: $grey;
    font-family: Montserrat-Bold;
    font-size: 0.85rem;
  }

  .react-select__menu {
    white-space: nowrap;
    width: unset;
    min-width: 100%;
    right: 0;
  }

  svg path {
    fill: $grey;
  }
}
