.agenda-page-desktop-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.agenda_desktop-calendar-container {
  flex-grow: 1;
  overflow: scroll;
  grid-area: content;
}

.agenda-page-desktop-container-iframe {
  display: grid;
  grid-template-areas: "menu container";
  grid-template-columns: 230px 1fr;
}

.desktop-toolbar-iframe {
  top: -20px;
}

.agenda_desktop-calendar-container-iframe {
  grid-area: content;
  position: relative;
  top: 18px;
}
