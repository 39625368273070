@import "styles/variables.scss";

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 110;
  display: flex;
  flex-direction: column;
}

.searchBar {
  flex-shrink: 0;
  height: 85px;
  width: 100%;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  box-shadow: 0 2px 6px 0 rgba(37, 39, 52, 0.25);
  z-index: 1;
}

.inputContainer {
  flex-grow: 1;

  input {
    font-size: 1.6rem;
    caret-color: $tealish;
  }
}

.closeContainer {
  &:hover {
    cursor: pointer;
  }
}

.searchResultsContainer {
  flex-grow: 1;
  transition: opacity 0.15s;
  opacity: 0.3;
  background-color: $dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  position: relative;

  &.searching {
    overflow-y: scroll;
    background-color: #f3f5f7;
    opacity: 1;
  }
}

.return {
  position: sticky;
  align-self: flex-start;
  left: 45px;
  top: 0;
  color: $bluey-grey;
  font-size: 0.94rem;

  &:hover {
    cursor: pointer;
    color: $dark;
  }
}

.title {
  font-family: Montserrat-Bold;
  font-size: 1.315rem;
  margin-bottom: 15px;

  span {
    font-family: Montserrat-Regular;
    color: $rating-extra-grey;
    opacity: 0.4;
  }
}
