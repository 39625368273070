@import "styles/variables.scss";
@import "styles/mixins.scss";

.tabsContainer {
  position: relative;
  width: calc(100vw + 3px);
}

.tabIndicator {
  height: 4px;
  border-radius: 2px;
  background-color: $white;
}

.tabContainer {
  font-family: Montserrat-Bold;
  font-size: 0.88rem;
  letter-spacing: 1px;
  color: $white;
}

@include desktop {
  .searchIcon {
    position: absolute;
    top: 80px;
    right: 50px;
    z-index: 100;

    &:hover {
      cursor: pointer;
    }
  }
}
