$sidebar-width: 250px;
$min-content-width: 350px;
$side-margin: 45px;

.container {
  padding-top: 40px;
  display: grid;
  grid: auto / $sidebar-width minmax(min-content, auto) minmax(
      $side-margin,
      $sidebar-width
    );
  grid-template-areas: "sidebar content padding";
  grid-column-gap: 25px;
}

.containerIframe {
  padding-left: 25px;
  grid: auto / auto;
  grid-template-areas: "content padding";
  grid-column-gap: 25px;
}

.sidebar {
  margin-left: $side-margin;
}

.content {
  overflow-y: scroll;
}
