@import "styles/mixins.scss";
@import "styles/variables.scss";

button.unselectedDay {
  font-family: "Montserrat-Bold";
  padding: 6px 9px;
  font-size: 0.8em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  background: lightgrey;
  border-color: lightgrey;
  color: grey;
}

button.selectedDay {
  font-family: "Montserrat-Bold";
  padding: 6px 9px;
  font-size: 0.8em;
  background: $blush-pink;
  border-color: $blush-pink;
  color: white;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
