@import "../../../styles/mixins.scss";

.container {
  display: flex;
  margin-top: 30px;

  &:last-child {
    margin-bottom: 70px;
  }

  @include mobile {
    &:first-child {
      margin-top: 15px;
    }
  }

  @include desktop {
    flex-direction: column;
    width: 100%;
    max-width: 100vw;
  }
}

.dateContainer {
  display: flex;
  flex-direction: column;
  color: $bluey-grey;
  margin-top: 5px;
  flex-shrink: 0;
  @include mobile {
    width: 62px;
    align-items: center;
  }

  @include desktop {
    margin-bottom: 10px;
  }
}

.dateName {
  text-transform: uppercase;

  @include mobile {
    font-size: 0.94rem;
  }

  @include desktop {
    font-size: 0.69rem;
    font-family: Montserrat-Bold;
  }
}

.dateNum {
  font-size: 1.313rem;
  margin-top: 5px;
}

.dateSlotsContainer {
  flex-grow: 1;
  margin-right: 15px;
}
