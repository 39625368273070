@import "../../../styles/mixins.scss";

@include desktop {
  .container {
    margin: 0 auto;
    height: calc(100% - 30px);
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;
    column-gap: 25px;
    grid-template-areas: "selector ratings";
  }

  .selectorContainer {
    grid-area: selector;
    height: 100%;
    overflow-y: scroll;
    min-width: 345px;
  }

  .ratingsContainer {
    grid-area: ratings;
    width: 100%;
    min-width: 550px;
    max-width: 800px;
    height: 100%;
    overflow-y: scroll;
  }

  .containerIframe {
    width: 100%;
    max-width: 100%;
    padding-left: 25px;
  }

  .ratingsContainerIframe {
    max-width: 550px;
  }
}
