.stickTopRight {
  position: absolute;
  right: 25px;
  top: 21px;
}

button.hairdresserNamePopin {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 20px;
}
