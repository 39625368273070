@import "styles/mixins.scss";

.currentPriceContainer {
  display: flex;
}

.titleCol {
  width: 100%;
}

.packName {
  font-size: 1rem;
}

.currentPriceCol {
  @include mobile {
    padding-left: 10px;
    padding-right: 15px;
    min-width: 90px;
  }

  @include desktop {
    white-space: nowrap;
  }
}

.newPriceCol {
  @include mobile {
    padding-left: 10px;
    padding-right: 15px;
    min-width: 100px;
  }

  @include desktop {
    white-space: nowrap;
  }

  input {
    text-align: right;
  }
}

.padding {
  @include mobile {
    width: 0;
    padding: 0;
  }
}
