@import "../../../styles/variables.scss";

.container {
  font-family: Montserrat-Bold;
  font-size: 1.13rem;
  color: $rating-grey;
}

.empasis {
  color: $blush-pink;
}
