@import "styles/variables.scss";
@import "styles/mixins.scss";

@include mobile {
  .container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }
}

.content {
  border-radius: 4px;
  border: solid 1px $very-light-blue;
  background-color: $white;
  padding: 25px;
  padding-bottom: 0;
}

.newHairdresserImageContainer {
  label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0.5px solid $very-light-blue;
    }

    span {
      margin-left: 15px;
      font-family: Montserrat-Bold;
      letter-spacing: 1px;
      font-size: 0.88rem;
      color: $tealish;
      text-transform: uppercase;
    }
  }

  input {
    display: none;
  }
}

.radiosContainer {
  display: flex;
}

.colorInputContainer {
  flex-grow: 1;
  margin-left: 15px;
}

.imageContainer {
  position: relative;
  max-width: fit-content;

  @include mobile {
    align-self: center;
  }

  img {
    display: block;
  }

  label {
    &:hover {
      cursor: pointer;
    }
  }

  input {
    display: none;
  }
}

.loaderContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: $very-light-blue70;

  &:hover {
    cursor: wait;
  }
}

.cameraIcon {
  position: absolute;
  bottom: 15px;
  right: 10px;
  filter: drop-shadow(0px 0px 5px black);
}

.image {
  object-fit: contain;

  @include mobile {
    height: 225px;
  }

  @include desktop {
    min-height: 130px;
    max-height: 225px;
    border-radius: 4px;
    border: solid 1px $very-light-blue;
  }
}

.colorPickerContainer {
  position: absolute;
  left: 0;
  right: 20px;

  @include mobile {
    padding: 3px;
    top: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  @include desktop {
    z-index: 1;
    top: 30px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
