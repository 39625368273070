@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.login-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    height: 100vh;
  }

  @include desktop {
    max-width: 458px;
    max-height: 590px;
    border-radius: 4px;
    border: 1px solid $very-light-blue;
    background-color: $white;
    margin: auto;
    margin-top: 55px;
    padding: 0 40px;
  }
}

.login-page-header {
  margin: 50px 35px 35px 35px;
  text-align: center;

  @include desktop {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.login-page-header__logo {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-top: -10px;
}

.login-page-header__title {
  margin-bottom: 5px;
}

.login-page-header__title {
  font-size: 1.7rem;
}

.login-page-header__description {
  margin: auto;
  max-width: 300px;
}

.login-page-header__description span {
  font-size: 0.94rem;
  line-height: 1.53;
  letter-spacing: 0.2px;
  color: $bluey-grey;
}

.login-page-header__form {
  margin: 20px;
}

.login-page-header__submit {
  width: 100%;
  margin-top: -20px;
}

.login-form_password-reset {
  display: block;
  width: 100%;
  text-align: right;
  margin-top: 10px;
  font-size: 0.813rem;
  letter-spacing: 0.2px;
  color: $tealish;
}

.login-form_password-reset:hover,
.login-page_signup-salon_button:hover {
  cursor: pointer;
}

.login-page_signup-salon_button {
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: underline;
  font-size: 0.813rem;
  color: $dark;
  letter-spacing: 0.1px;
  margin-bottom: 25px;
}

.reset-password-modal div {
  font-size: 1.2rem;
}
