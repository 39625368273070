@import "../../../../styles/variables.scss";

.link {
  color: $bluey-grey;
  margin-bottom: 15px;
  display: block;
  font-size: 0.94rem;

  &.selected {
    color: $dark;
  }

  &:hover {
    cursor: pointer;
  }
}
