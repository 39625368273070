@import "styles/variables.scss";

.container {
  width: 290px;
  height: 100%;
  padding: 70px 40px 40px 25px;
}

.titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 2.88rem;
  }
}

.closeButton {
  flex-shrink: 0;
}

.filterGroup {
  margin-top: 55px;

  &:first-child {
    margin-top: 40px;
  }

  .title {
    font-family: Montserrat-Bold;
    font-size: 1.313rem;
    display: inline-block;
    margin-bottom: 15px;
  }

  .labelRoot {
    margin-left: -7.5px;
  }

  .label {
    margin-left: 4.5px;
  }

  .checkbox {
    padding: 7.5px;
  }
}

.disabledHairdressersToggle {
  letter-spacing: 0.2px;
  font-size: 0.813rem;
  color: $bluey-grey;
  margin: 5px;

  &:hover {
    cursor: pointer;
  }
}
