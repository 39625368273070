@import "styles/mixins.scss";
@import "styles/variables.scss";

.listContainer {
  @include mobile {
    padding-top: 15px;
  }

  @include desktop {
    height: 100%;
    overflow-y: scroll;
  }
}

.toggleDisabled {
  letter-spacing: 0.2px;
  font-size: 0.813rem;
  color: $bluey-grey;
  text-align: center;

  @include desktop {
    margin: 10px 0;
  }

  @include mobile {
    margin: 25px 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.mainContainer {
  border: 1px solid #e7ebef;
  background-color: white;
  border-radius: 4px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  h2 {
    font-family: "Montserrat-Bold";
  }
}

.lightBorder {
  border-bottom: 1px solid #e7ebef;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.container {
  padding: 15px;
  h3 {
    font-family: "Montserrat-Bold";
    font-size: 1em;
    margin-bottom: 0px;
  }
}

@include desktop {
  .container {
    height: 100%;
    max-height: calc(100% - 25px);
    max-width: 1200px;
    margin: 0 auto;
  }

  .addButtonContainer {
    text-align: center;

    span {
      margin-top: 25px;
      font-family: Montserrat-Bold;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.86px;
      color: $tealish;
      display: inline-flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .hairdresserForm {
    width: 100%;
    min-width: 315px;
    max-width: 700px;
    margin: 0 auto;
    overflow-y: scroll;
  }
}

.slotHours {
  display: inline-block;
  vertical-align: middle;
}

.slotHour {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.separator {
  @include mobile {
    margin-left: 0;
  }
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.separatorHideMobile {
  @include mobile {
    display: none;
    margin-left: 0;
  }
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.dayPart {
  margin-left: 10px;
  margin-right: 10px;
}

.dayFormLine {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  @include mobile {
    grid-template-columns: 100%;
    padding-left: 0;
  }
}

.dayFormLine:nth-child(odd) {
  background: #f6f6f7;
}

.timePickers {
  display: flex;
  @include mobile {
    display: grid;
    grid-template-columns: 100%;
  }
}

.singlePicker {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: 45% 45% 5%;
  max-width: 400px;
  align-items: center;
  justify-content: center;
}

.singlePickerSeparator {
  @extend .singlePicker;
  grid-template-columns: 5% 45% 45% 5%;
  @include mobile {
    grid-template-columns: 45% 45% 5%;
  }
}

.shortPicker {
  display: inline-block;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 95px;
  & input {
    margin-left: 5px;
    margin-top: -5px;
    font-size: 1.5em;
    color: $blush-pink;
    cursor: pointer;
  }
  & > span {
    top: 0;

    &::after {
      height: 0px;
    }
  }
  @include mobile {
    & > label {
      font-size: 0.5em !important;
    }
    & input {
      font-size: 0.8em !important;
    }
  }
}

.hairdresserDay,
.hairdresserDayClosed {
  margin-top: 10px;
  font-size: 0.9em;
  & > b {
    font-size: 0.9em;
    font-family: "Montserrat-Bold";
  }
}

.hairdresserDay {
  display: grid;
  grid-template-columns: 25% 75%;
  text-align: left;
  align-items: center;
  padding-right: 5px;
}

.hairdresserDayClosed {
  display: grid;
  grid-template-columns: 100%;
  text-align: left;
  color: #d3d3d3;
}

.hairdresserWeeksContainer {
  grid-template-columns: 45% 55%;
  @include mobile {
    grid-template-columns: 100%;
  }
  display: grid;
  border-top: 1px solid #e7ebef;
  border-bottom: 1px solid #e7ebef;
  padding-top: 20px;
  padding-bottom: 20px;

  & > div {
    margin-bottom: 25px;
    @include desktop {
      &:nth-child(even) {
        padding-left: 30px;
        border-left: 1px solid #e7ebef;
      }
    }
  }
}

button.saveButton {
  font-family: "Montserrat-Bold";
}

.inlineBtn {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1px;
}

.stickTopRight {
  position: absolute;
  right: 25px;
  top: 21px;
}

.mobileContainer {
  padding: 20px;
}

.hButtons {
  button.hairdresserBtn,
  button.hairdresserBtn.button-desktop.button-desktop--primary {
    font-family: "Montserrat-Bold";
    color: white;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 0px;
    margin-top: 0px;
    padding: 5px;
  }
}

.bold,
button.bold {
  font-family: "Montserrat-Bold";
}

.hairdresserName {
  font-family: "Montserrat-Bold";
  margin-bottom: 5px;
}

button.flatBtn {
  font-family: "Montserrat-Bold";
  padding: 8px 18px;
  font-size: 0.8em;
}

.dayInput {
  width: 55px;
  font-size: 1.5em;
  border: none;
  color: $blush-pink;
  background: none;
  margin-left: 5px;
}

.hButtons {
  text-align: left;
}

.plusButton {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1px;
  margin-bottom: 10px;
  margin-right: 10px;
}

button.hairdresserNamePopin {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 20px;
}

button.textButton {
  background: unset;
  color: grey;
  border: none;
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

button.unselectedDay {
  font-family: "Montserrat-Bold";
  padding: 6px 9px;
  font-size: 0.8em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  background: lightgrey;
  border-color: lightgrey;
  color: grey;
}

button.selectedDay {
  font-family: "Montserrat-Bold";
  padding: 6px 9px;
  font-size: 0.8em;
  background: $blush-pink;
  border-color: $blush-pink;
  color: white;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

button.hairdresserBtn,
button.hairdresserBtn.button-desktop.button-desktop--primary {
  font-family: "Montserrat-Bold";
  color: white;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 0px;
  margin-top: 0px;
  padding: 5px;
}

.conflictBox {
  padding: 30px;
  color: white;
  background: #f93b21;
}

.promoSelect {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.firstDiscount {
  border-radius: 5px 0px 0px 5px;
  background-color: lightgrey;
  color: darkgrey;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 20px;
  font-family: Montserrat-Bold;
  cursor: pointer;
  @include mobile {
    padding: 5px 10px;
  }
}

.firstDiscountSelected {
  @extend .firstDiscount;
  background-color: $blush-pink;
  color: white;
}

.secondDiscount {
  border-radius: 0px 5px 5px 0px;
  background-color: lightgrey;
  color: darkgrey;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 20px;
  font-family: Montserrat-Bold;
  cursor: pointer;
  @include mobile {
    padding: 5px 10px;
  }
}

.secondDiscountSelected {
  @extend .secondDiscount;
  background-color: $blush-pink;
  color: white;
}

.hhTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -10px;
  h3 {
    margin-top: 0;
  }
  div {
    margin-left: 10px;
  }
}

.promoSelector {
  margin-bottom: 20px;
}

.lastMinuteContainer {
  margin-bottom: 20px;
}

.minutesBeforeLabel {
  display: inline;
  margin-right: 10px;
}
