@import "../../../styles/variables.scss";

.container {
  display: flex;
  align-items: center;
}

.hairdresserSelect {
  min-width: 200px;
}

.datePickerContainer {
  position: relative;
  margin-left: 10px;
}

.input {
  padding: 12px 25px 10px 15px;
}

.pickerArrow {
  position: absolute;
  right: 13px;
  bottom: 14px;
}

.toolbarShowCanceled {
  margin-left: 10px;
}

.showCanceledMobileLabel {
  font-size: 0.8em;
}

.showCanceledDesktopLabel {
  max-height: 25px;
}

.label {
  white-space: nowrap;
}
