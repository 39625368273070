@import "styles/variables.scss";
@import "styles/mixins.scss";

.desktopModalContent {
  padding: 30px;
  border-radius: 4px;
  border: solid 1px $very-light-blue;
  background-color: $white;
  max-width: 750px;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.title {
  font-family: Montserrat-Bold;
  color: $dark;
  font-size: 1.25rem;
}

.phoneInput {
  width: 45%;
}

.lastGroup {
  margin-bottom: 50px;
}

.contact {
  margin-top: 30px;
  font-size: 1rem;

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  a {
    text-decoration: underline;
    color: $tealish;

    @include desktop {
      margin-right: 5px;
    }
  }
}
