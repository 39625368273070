@import "../../../styles/variables.scss";

.calendar-mode-toggle {
  display: flex;
  font-size: 0.88rem;
  color: $rating-grey;

  span {
    opacity: 0.3;

    &:hover {
      opacity: 0.4;
      cursor: pointer;
    }
  }

  .selected {
    color: $tealish;

    span {
      opacity: 1;
    }
  }

  div:not(:last-child) {
    margin-right: 25px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 25px;
      top: -2.5px;
      right: -13.5px;
      background-color: #d3d5d8;
    }
  }
}
