@import "../../../styles/variables.scss";

.container {
  border: solid 3px;
}

.image {
  composes: container;
  object-fit: cover;
}

.initialsContainer {
  composes: container;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat-Bold;
  background-color: $white;
}
