@import "../../../../styles/variables.scss";

.container {
  text-align: center;
  line-height: 1.63;
  /* Text selection */
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.name {
  font-family: Montserrat-Bold;
  font-size: 1.25rem;
  color: $dark;
}

.email {
  color: $bluey-grey;
}

.phone {
  color: $tealish;
  text-underline-position: under;
}
