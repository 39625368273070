@import "../../../styles/variables.scss";

.menu-mobile-drawer__paper {
  overflow: unset !important;
}

.menu-mobile-container {
  position: relative;
  width: 345px;
  max-width: 90vw;
  padding: 25px 25px 20px 20px;
  height: 100%;

  // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-top: constant(safe-area-inset-top)) {
    & {
      --safe-area-inset-top: constant(safe-area-inset-top);
      padding: calc(var(--safe-area-inset-top) + 25px) 25px 20px 20px;
    }
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-top: env(safe-area-inset-top)) {
    & {
      --safe-area-inset-top: env(safe-area-inset-top);
      padding: calc(var(--safe-area-inset-top) + 25px) 25px 20px 20px;
    }
  }
}

.menu-mobile-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -30px;
  margin-left: -35px;
  margin-top: -40px;
}

.menu-mobile-header__title {
  display: inline;
  margin-left: 8px;
  font-size: 1.25rem;
}

.menu-mobile-content {
  color: $dark;
  position: relative;
}

.menu-mobile-list_item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.menu-mobile-list_item::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  border: solid 0.5px $very-light-blue;
}

.menu-mobile-list_item__text {
  font-family: Montserrat-Bold;
  text-transform: uppercase;
  color: $dark;
  letter-spacing: 0.8px;
  font-size: 0.813rem;
  opacity: 0.8;
}

.menu-mobile-footer {
  position: absolute;
  bottom: 0;

  // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-top: constant(safe-area-inset-bottom)) {
    & {
      --safe-area-inset-bottom: constant(safe-area-inset-bottom);
      bottom: var(--safe-area-inset-bottom, 0);
    }
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-top: env(safe-area-inset-bottom)) {
    & {
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      bottom: var(--safe-area-inset-bottom, 0);
    }
  }
}

.menu-mobile-over-container-iframe {
  background-color: #ee7c56;
  width: auto;
}

div.menu-mobile-container-iframe {
  max-height: 80%;
  text-align: left;

  .menu-desktop-nav-iframe {
    font-size: 1.1em;
    width: 100%;
    .menu-item {
      padding: 10px 10px;
      padding-left: 18px;
    }
  }
}

div.menu-mobile-header-iframe {
  margin: 0;
  color: white;
  align-items: baseline;

  .mkpTitle {
    font-size: 1.1em;
    flex: 0.95;
  }
}
