@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.containerBase {
  border: solid 1px;
  background-color: $white;
  display: flex;
}

.container {
  composes: containerBase;
  border-radius: 2px;
  margin-bottom: 5px;
  padding: 10px;
}

.desktopContainer {
  composes: containerBase;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 22px 15px 17px 15px;

  &:hover {
    cursor: pointer;
  }

  .leftContainer {
    width: 215px;
    display: flex;
    flex-shrink: 0;

    .text {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      font-size: 0.9rem; // 13px

      > span {
        margin-bottom: 5px;
      }

      .hairdresserfirstName {
        font-family: Montserrat-Bold;
        font-size: 1rem;
      }
    }
  }

  .centerContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    > span:not(:first-child),
    > div:not(:first-child) {
      margin-bottom: 5px;
    }

    .title {
      display: flex;
      align-items: center;
      margin-top: -5px;

      .variant {
        margin-left: 10px;
      }
    }

    .description {
      font-size: 0.9rem;
      color: #8c8c8c;
    }

    .userInfos {
      display: flex;
      font-size: 0.85rem;
      color: $bluey-grey;

      span:not(:first-child) {
        margin-left: 10px;
      }

      .userFullname {
        color: $rating-grey;
      }
    }
  }

  .priceContainer {
    width: 120px;
    align-items: baseline;
    justify-content: flex-end;

    .priceReduced {
      margin-left: 7px;
      font-size: 1.125rem;
    }

    .priceFull {
      font-size: 0.88rem;
    }
  }
}

.content {
  flex-grow: 1;
}

.name {
  font-size: 0.94rem;

  span:first-child {
    font-family: Montserrat-Bold;
  }
}

.package {
  display: flex;
  font-size: 0.75rem;
  color: $rating-grey;
  margin-top: 5px;
  align-items: center;
}

.variant {
  border-radius: 4px;
  background-color: $very-light-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  margin-left: 5px;
}

.hairdresserName {
  opacity: 0.4;
  font-size: 0.69rem;
  margin-top: 5px;
}

.priceContainer {
  flex-shrink: 0;
  display: flex;

  .priceFull {
    opacity: 0.3;
    font-family: Montserrat-Bold;
    font-size: 0.94rem;
    color: $rating-grey;
    text-decoration: line-through;
  }

  .priceReduced {
    margin-left: 5px;
    font-family: Montserrat-Bold;
    font-size: 0.94rem;
  }
}

.inSalonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.inSalon {
  margin-left: 5px;
  color: $blush-pink;
  font-weight: bold;
  font-size: 0.9em;
  @include mobile {
    font-size: 0.8em;
  }
}
