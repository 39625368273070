.paper {
  max-height: calc(100vh - 70px);
  overflow-y: visible;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.close {
  position: absolute;
  right: 5px;
  top: -60px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  overflow-y: scroll;
}
