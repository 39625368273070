.package-form--desktop-content-container {
  min-width: 600px;
}

.package-form-variant {
  margin-left: 15px;
  margin-top: 15px;
}

.package-item-input-title {
  font-family: Montserrat-Bold;
  text-transform: uppercase;
  font-size: 0.69rem;
  letter-spacing: 0.68px;
  color: $bluey-grey;
  flex-shrink: 0;
}

.package-price-input-render-values > .full-price {
  color: $light-blue-grey;
  text-decoration: line-through;
  margin-right: 10px;
}

.package-price-input-container {
  position: relative;
  width: 100%;
  height: 23px;
  line-height: 23px;
}

.package-price-input-container > input {
  position: absolute;
  bottom: 0;
  right: 10px;
  padding-right: 2px;
  padding-bottom: 0;
  padding-top: 0;
  opacity: 0;
  border: none;
  text-align: right;
  height: 23px;
  line-height: 23px;
  font-size: 1rem;
  outline: none;
}

.package-price-input-container > input::-webkit-inner-spin-button {
  display: none;
}

.package-price-input-container > input:focus {
  opacity: 1;
}

.package-price_select-hairdressers-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  min-width: 0; // https://css-tricks.com/flexbox-truncated-text/
}

.package-price_select-hairdressers {
  padding: 0 10px 0 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.package-price-package-number {
  color: $light-blue-grey;
}

.package-partner-form-container {
  width: 100%;
  display: block;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 15px;
  .single-partner-form {
    margin: 10px;
  }
}
