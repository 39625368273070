@import "styles/variables.scss";

.title {
  font-family: Montserrat-Bold;
  font-size: 1.25rem;
}

.container {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

.imageContainer {
  position: relative;

  .loaderContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: $very-light-blue70;
  }

  .deleteIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    filter: drop-shadow(0 0 2px black);

    &:hover {
      cursor: pointer;
    }
  }

  img,
  label {
    width: 78px;
    height: 69px;
    border-radius: 3px;
    object-fit: cover;
  }

  label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $very-light-blue;

    &:hover {
      cursor: pointer;
    }
  }

  input {
    display: none;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
}
