@import "../../../styles/variables.scss";

.agenda-toolbar-slots-buttons,
.agenda-toolbar-filters-group {
  display: flex;
  align-items: center;
}

.agenda-toolbar-slots-buttons > button:not(:last-child) {
  margin-right: 10px;
}

.agenda-toolbar-filters-group button {
  margin-right: 25px;
}
