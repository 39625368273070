@import "../../../styles/variables.scss";

.calendar-date-picker-group--desktop {
  display: flex;
  align-items: center;
  width: 300px;
  position: relative;
}

.calendar-date-picker-move-button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 9px 0 rgba(37, 39, 52, 0.19);
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;

  svg {
    path {
      stroke: $rating-grey;
    }
  }

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:hover {
    box-shadow: 0 2px 9px 1px rgba(37, 39, 52, 0.25);

    &:active {
      box-shadow: 0 2px 9px -1px rgba(37, 39, 52, 0.25);
    }
  }
}

.calendar-date-picker-full-date {
  position: relative;
  left: 15px;
  cursor: pointer;

  span {
    color: $rating-grey;
    font-family: Montserrat-Bold;
    font-size: 0.9rem;
    cursor: pointer;
    white-space: nowrap;
  }

  &:hover {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      height: 1px;
      background-color: $rating-grey;
      opacity: 0.1;
    }
  }
}

.calendar-date-picker-full-date-popover {
  overflow: unset;
  margin-top: 15px;

  svg {
    fill: $light-blue-grey;
  }

  .MuiPickersCalendarHeader-transitionContainer {
    p {
      text-transform: uppercase;
      font-family: Montserrat-Bold;
      letter-spacing: 0.88px;
      font-size: 0.88rem;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid $white;
  }
}
