@font-face {
  font-family: Montserrat-Regular;
  font-display: fallback;
  src: local(Montserrat-Regular),
    url(../static/fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Montserrat-Medium;
  font-display: fallback;
  src: local(Montserrat-Medium),
    url(../static/fonts/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: Montserrat-Bold;
  font-display: fallback;
  src: local(Montserrat-Bold),
    url(../static/fonts/Montserrat-Bold.ttf) format("truetype");
}
