@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

%separator {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  border: solid 0.5px $light-grey;
  opacity: 0.3;
}

.container {
  background-color: $white;
  min-width: 100%;

  @include mobile {
    padding: 20px 15px;
    border-top: solid 1px $very-light-blue;
    border-bottom: solid 1px $very-light-blue;
  }

  @include desktop {
    padding: 25px 20px;
    border-radius: 4px;
    border: solid 1px $very-light-blue;
    width: 100%;
    min-width: 550px;
    max-width: 800px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;

    @include desktop {
      margin-bottom: 15px;
    }
  }

  &.expanded {
    padding: unset;
    border: unset;
  }
}

.headerContainer {
  position: relative;
  display: flex;
  justify-content: space-between;

  &::after {
    @extend %separator;
  }

  .left {
    display: flex;
  }

  .title {
    margin-left: 10px;
    font-family: Montserrat-Bold;
    color: $dark;
  }

  .right {
    font-size: 0.88rem;
    color: $rating-light-grey;
    text-align: right;
  }
}

.body {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .title {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .right {
      margin-top: 5px;
    }
  }
}

.package {
  color: $dark;
  margin-bottom: 10px;
}

.hairdresser {
  font-size: 0.88rem;
  color: $rating-light-grey;
  margin-bottom: 10px;

  @include desktop {
    text-align: right;
  }
}

.comment {
  position: relative;
  font-size: 0.88rem;
  color: $dark;
  line-height: 1.43;
}

.replyContainer {
  display: flex;
  margin-top: 15px;
  position: relative;

  &::before {
    content: "";
    border: solid 1.5px $tealish;
    margin-left: 5px;
    margin-right: 15px;
    opacity: 0.5;
  }

  &:first-child {
    margin-top: 35px;

    &::after {
      @extend %separator;
      left: 10px;
      right: 10px;
      bottom: unset;
      top: -20px;
    }
  }
}

.replyInnerContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .content {
    color: $dark;
    line-height: 1.43;
    font-size: 0.88rem;
    opacity: 0.8;
  }

  .meta {
    margin-top: 8px;
    opacity: 0.4;
    font-size: 0.69rem;
    color: $dark;
  }
}

.replyInputContainer {
  position: relative;
  width: 100%;
}

.replyTextField {
  margin-top: 25px;

  .inputRoot {
    border-radius: 8px;
    line-height: 1.5rem;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .notchedOutline {
    border: solid 1px $border-grey;
  }
}

.labelRoot {
  font-size: 0.88rem;
  color: $dark20;
  top: -2px;
}

.labelShrink {
  top: 0;
}

.submitReply {
  margin: 5px 0 !important;
}
