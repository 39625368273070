@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;

  @include mobile {
    min-width: 132px;
    min-height: 188px;
    border-radius: 5px;
    padding: 12px;
    text-align: center;

    * {
      opacity: 0.8;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.selected {
      box-shadow: 0 4px 12px 0 rgba(44, 68, 102, 0.23);

      * {
        opacity: 1;
      }
    }
  }

  @include desktop {
    cursor: pointer;
    flex-direction: row;
    padding: 20px;
    border: solid 1px $very-light-blue;
    min-width: 345px;

    &:hover {
      background-color: $white20;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:not(:first-child) {
      border-top: unset;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.selected {
      background-color: $very-light-blue30;

      .name {
        font-family: Montserrat-Bold;
      }
    }
  }
}

@include desktop {
  .responsiveContainer {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-self: normal;
  }
}

.infosContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    margin-top: 12px;
  }

  @include desktop {
    margin-left: 25px;
    align-self: normal;
  }
}

.name {
  font-size: 0.94rem;
  color: $dark;

  @include desktop {
    font-size: 1.19rem;
  }

  .disabled {
    font-size: 0.72rem;
    color: $bluey-grey;
    opacity: 0.8;
  }
}
