@import "../../styles/variables.scss";

.signup-title,
.signup-form-label {
  display: block;
}

.signup-title {
  font-size: 1.19rem;
  letter-spacing: 0.19px;
  color: $dark;
  line-height: 1.37;
}

.signup-form-label {
  margin-top: 20px;
  margin-bottom: 25px;
  font-size: 0.95rem;
  letter-spacing: 0.15px;
  color: $bluey-grey;
  line-height: 1.53;
}

span.signup-submit-helper-text {
  display: block;
  font-size: 0.813rem;
  line-height: 1.46;
  text-align: center;
  color: $bluey-grey;
  width: 85%;
  margin: auto;
}

.signup-success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .signup-success-modal-title {
    font-size: 1.38rem;
    color: $dark;
    margin-bottom: 10px;
  }

  .signup-success-modal-message {
    font-size: 1.13rem;
    color: $bluey-grey;
    margin-bottom: 10px;
  }

  .signup-success-modal-button {
    font-family: Montserrat-Bold;
    font-size: 0.88rem;
    letter-spacing: 1px;
    color: $tealish;
  }
}

.signup-page-form {
  margin-bottom: 5px;
}

.signup-page-desktop-container {
  .signup-submit-helper-text {
    width: 100%;
  }
}
