@import "styles/mixins.scss";
@import "styles/variables.scss";

.shortPicker {
  display: inline-block;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 95px;
  & input {
    margin-left: 5px;
    margin-top: -5px;
    font-size: 1.5em;
    color: $blush-pink;
    cursor: pointer;
  }
  & > span {
    top: 0;

    &::after {
      height: 0px;
    }
  }
  @include mobile {
    & > label {
      font-size: 0.5em !important;
    }
    & input {
      font-size: 0.8em !important;
    }
  }
}

.dayInput {
  width: 55px;
  font-size: 1.5em;
  border: none;
  color: $blush-pink;
  background: none;
  margin-left: 5px;
}
