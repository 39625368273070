@import "../../../styles/variables.scss";

.desktopContainer {
  padding: 0 35px 30px 35px;

  &.cancellable {
    padding-bottom: 0;
  }

  &.withDetails {
    min-width: 800px;
  }

  .clientInfos {
    margin-bottom: 45px;
  }

  .detailsContainer {
    padding-left: 71px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: 36px;
      width: 1px;
      background-color: $very-light-blue;
    }
  }
}

.detailsTitle {
  font-family: Montserrat-Bold;
  font-size: 1.25rem; //20px;
  margin-bottom: 5px;
  display: inline-block;
}

.detailsPriceContainer {
  font-family: Montserrat-Bold;
}

.detailsPriceFull {
  font-size: 0.88rem;
  color: $light-blue-grey;
  text-decoration: line-through;
}

.detailsPriceReduced {
  font-size: 1rem;
  margin-left: 5px;
  color: $blush-pink;
}
