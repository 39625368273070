@import "styles/mixins.scss";
@import "styles/variables.scss";

.promoSelect {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.firstDiscount {
  border-radius: 5px 0px 0px 5px;
  background-color: lightgrey;
  color: darkgrey;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 20px;
  font-family: Montserrat-Bold;
  cursor: pointer;
  @include mobile {
    padding: 5px 10px;
  }
}

.firstDiscountSelected {
  @extend .firstDiscount;
  background-color: $blush-pink;
  color: white;
}

.middleDiscount {
  border-radius: 0px;
  background-color: lightgrey;
  color: darkgrey;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 20px;
  font-family: Montserrat-Bold;
  cursor: pointer;
  @include mobile {
    padding: 5px 10px;
  }
}

.middleDiscountSelected {
  @extend .middleDiscount;
  background-color: $blush-pink;
  color: white;
}

.lastDiscount {
  border-radius: 0px 5px 5px 0px;
  background-color: lightgrey;
  color: darkgrey;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 20px;
  font-family: Montserrat-Bold;
  cursor: pointer;
  @include mobile {
    padding: 5px 10px;
  }
}

.lastDiscountSelected {
  @extend .lastDiscount;
  background-color: $blush-pink;
  color: white;
}
