@import "styles/mixins.scss";

.menuItemRoot {
  display: flex;
  justify-content: center;
}

.select {
  padding: 10px 35px 10px 15px;
  height: 23px;
  width: 23px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.icon {
  margin-top: 7px;
  margin-right: 15px;
}

.selectInput {
  @include mobile {
    margin-left: 20px;
  }
  margin-right: 15px;
}
