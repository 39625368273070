@import "../../../styles/variables.scss";

.external-page-desktop-container {
  width: 458px;
  margin: auto;
  border: solid 1px $very-light-blue;
  margin-top: 60px;
  padding: 80px 60px 30px 60px;
  position: relative;
  background-color: $white;
}

.external-page-desktop-back-button {
  position: absolute;
  left: 23px;
  top: 20px;
  color: $very-light-blue;
  font-family: Montserrat-Bold;
  font-size: 0.69rem;
  letter-spacing: 0.68px;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    color: $dark;
  }
}

.external-page-desktop-content-container {
  > span:first-child {
    font-family: Montserrat-Bold;
    font-size: 1.69rem;
    margin-bottom: 25px;
    display: block;
  }

  button.button {
    width: 100%;
  }
}
