@import "styles/variables.scss";
@import "styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;

  @include desktop {
    cursor: pointer;
  }
}

.infosContainer {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  flex: 1;
}

.title {
  font-family: Montserrat-Bold;
  letter-spacing: 0.2px;
  color: $dark;
  font-size: 14px;
  margin-left: 5px;
}

.date {
  font-family: Montserrat;
  font-size: 14px;
}

.value {
  position: relative;
  font-family: Montserrat-Bold;
  letter-spacing: 0.2px;
  padding: 6px 12px;
  color: $tealish;
  min-width: 100px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 3px;
    border: solid 1px #e9ecf0;
  }
}
