@import "styles/variables.scss";
@import "styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  padding: 15px 0;

  @include desktop {
    &:hover {
      cursor: pointer;
    }
  }
}

.infosContainer {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  flex: 1;
}

.title {
  font-family: Montserrat-Bold;
  letter-spacing: 0.2px;
  color: $dark;
}

.subtitle {
  letter-spacing: 0.2px;
  font-size: 0.813rem;
  color: $bluey-grey;
  padding-top: 5px;
}

.value {
  font-family: Montserrat-Bold;
  letter-spacing: 0.2px;
  color: $blush-pink;
  flex: 1;
  text-align: center;
  align-self: center;
}
