.payment_turnover-period_form-extra-text-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.payment_turnover-period_form-extra-text-mobile span {
  color: $dark;
  opacity: 0.4;
}

.payment_turnover-period_date_select_group-mobile {
  margin-bottom: 15px;
}

.list-by-date_container {
  margin-top: 0px;
}

.list-by-date_date_text {
  position: relative;
  display: block;
  font-family: Montserrat-Bold;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: normal;
  color: $grey-50;
  background-color: $very-light-blue;
  padding: 5px 20px;
}

.list-by-date_date_text::after {
  content: "";
  position: absolute;
  left: -15px;
  bottom: -15px;
  @include mobile {
    width: 100vw;
  }
}

.list-by-date_item_container {
  position: relative;
}

.list-by-date_item_container::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid $very-light-blue;
}

.payment_list-detail-group {
  font-size: 0.813rem;
  letter-spacing: 0.1px;
  color: $bluey-grey;
  margin-top: 12px;
  margin-bottom: 12px;
}

.payment_turnover-period_form-picker-arrow {
  position: absolute;
  right: 0;
}

.prestation-details-label {
  font-size: 0.813rem;
  letter-spacing: 0.1px;
}

.prestation-details-info {
  font-size: 0.813rem;
  letter-spacing: 0.13px;
  color: $bluey-grey;
}
