@import "./variables.scss";

/*
** MOBILE
*/

.modal_container-mobile {
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;
}

.modal_header-mobile {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  height: 70px;
  padding-top: 35px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-image: $backgroud-gradient;
  outline: none;
}

// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-top)) {
  .modal_header-mobile {
    --safe-area-inset-top: constant(safe-area-inset-top);
    height: calc(var(--safe-area-inset-top) + 70px);
    padding-top: calc(var(--safe-area-inset-top) + 35px);
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-top)) {
  .modal_header-mobile {
    --safe-area-inset-top: env(safe-area-inset-top);
    height: calc(var(--safe-area-inset-top) + 70px);
    padding-top: calc(var(--safe-area-inset-top) + 35px);
  }
}

.modal_header_icon-mobile {
  position: absolute;
  top: 38px;
  left: 17px;
  cursor: pointer;
}

// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-top)) {
  .modal_header_icon-mobile {
    --safe-area-inset-top: constant(safe-area-inset-top);
    top: calc(var(--safe-area-inset-top) + 38px);
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-top)) {
  .modal_header_icon-mobile {
    --safe-area-inset-top: env(safe-area-inset-top);
    top: calc(var(--safe-area-inset-top) + 38px);
  }
}

.modal_header_title-mobile {
  color: $white;
  width: 100%;
  font-family: Montserrat-Bold;
  font-size: 16px;
  text-align: center;
}

.modal-layout-footer_wrapper-mobile {
  width: 100%;
  padding-bottom: 0;
  position: fixed;
  bottom: 0;
}

// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-bottom)) {
  .modal-layout-footer_wrapper-mobile {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    padding-bottom: var(--safe-area-inset-bottom, 0);
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-bottom)) {
  .modal-layout-footer_wrapper-mobile {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding-bottom: var(--safe-area-inset-bottom, 0);
  }
}

.dense-list_item-container-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  box-sizing: content-box;
}

.dense-list_item-container-mobile span {
  color: $dark;
  letter-spacing: 0.2px;
}

.dense-list_item-right_container-mobile {
  display: flex;
  align-items: center;
}

.dense-list_item-right_container-mobile span {
  margin-right: 13px;
  font-family: Montserrat-Bold;
  letter-spacing: 0.2px;
  color: $dark;
  text-transform: capitalize;
}

.modal_footer-mobile {
  position: absolute;
  bottom: 0;
  width: 100%;
}

// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-bottom)) {
  .modal_footer-mobile {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    bottom: var(--safe-area-inset-bottom, 0);
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-bottom)) {
  .modal_footer-mobile {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    bottom: var(--safe-area-inset-bottom, 0);
  }
}

.modal-layout-wrapper-mobile {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal-layout-body_wrapper-mobile {
  flex-grow: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-layout-body_wrapper-mobile.plainBackground {
  background-color: $white;
}

.modal-layout-body_content-mobile {
  flex-grow: 1;
  height: 0; // Fixes Webkit bug : https://bugs.webkit.org/show_bug.cgi?id=198375
  min-height: fit-content;
  display: flex;
  flex-direction: column;
}

.modal-layout-body_content-mobile.dense {
  flex-grow: unset;
  height: unset;
}

/*
** DESKTOP
*/

.modal_outter-container-desktop {
  height: 100%;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal_container-desktop {
    max-height: 100%;
    margin: 25px;
    outline: none;
    border-radius: 7px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    padding: 25px;
    position: relative;
  }

  &.modal_outter-container-desktop--full-width {
    width: 100%;

    .modal_container-desktop {
      width: 100%;
    }
  }
}

.modal_header-desktop {
  text-align: center;
  min-height: 20px;
  padding-bottom: 15px;
}

.modal_header_close-icon-desktop {
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal_header_title-desktop {
  margin-top: 30px;
  font-size: 1.38rem;
  color: $dark;
}
