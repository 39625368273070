@import "../../../styles/variables.scss";

.backdrop {
  width: 100vw;
  height: 100vh;
}

.mobileBackdrop {
  composes: backdrop;
  background-color: $dark50 !important;
}

.desktopBackdrop {
  composes: backdrop;
  background-color: $dark60 !important;
}

.outterContainer {
  width: 100vw;
  height: 100vh;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerContainer {
  max-height: 100%;
  outline: none;
  background-color: $white;
  position: relative;
}

.mobileInnerContainer {
  composes: innerContainer;
  margin: 25px;
  border-radius: 7px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
  padding: 25px;
}

.desktopInnerContainer {
  composes: innerContainer;
  border-radius: 4px;
  box-shadow: 0 2px 24px 0 rgba(37, 39, 52, 0.37);
}
