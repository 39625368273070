@import "../../../styles/variables.scss";

.desktopContainer input,
.desktopPopover li,
.desktopPopover input {
  font-size: 1rem;
}

.desktopContainer input,
.desktopPopover li:not([disabled]),
.desktopPopover input {
  font-size: 1rem;
  color: $dark;
}

.desktopContainer input {
  background-color: transparent;
  border: none;
  padding-left: 0;
}

.desktopPicker {
  position: relative;
  top: 5px;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $very-light-blue;
  }

  &:hover {
    &::after {
      height: 2px;
      background-color: $dark;
    }
  }
}

.desktopIcon {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.desktopPopover {
  z-index: 2100;

  :global(.rc-time-picker-panel-select) {
    max-height: 300px;
  }
}
