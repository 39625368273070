@import "styles/mixins.scss";
@import "styles/variables.scss";

.outterContainer {
  @include mobile {
    padding: 20px 13px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.outterContainer,
.container {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;

  @include desktop {
    flex-direction: column;
  }
}

.toggle {
  letter-spacing: 0.2px;
  font-size: 0.813rem;
  color: $bluey-grey;
  margin: 5px 0;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  @include mobile {
    align-self: center;
    padding: 0px 15px;
  }
}

.collapseWrapperInner {
  @include mobile {
    display: flex;
  }

  @include desktop {
    width: min-content;
  }
}
