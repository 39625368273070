@import "styles/variables.scss";

.container {
  min-width: 550px;
  max-width: 1000px;
  margin: auto;
  max-height: calc(100% - 30px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  column-gap: 15px;
  row-gap: 15px;
  grid-template-areas:
    "header header"
    "selectHairdresser selectDate"
    "prestations prestations";
}

%headerText {
  font-family: Montserrat-Bold;
  font-size: 1.25rem;
}

.header {
  @extend %headerText;
  grid-area: header;
}

%contentAreaStyle {
  border-radius: 4px;
  border: solid 1px $very-light-blue;
  background-color: $white;
}

%selectAreaStyle {
  @extend %contentAreaStyle;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.label {
  color: $bluey-grey;
  letter-spacing: 0.16px;
  margin-right: 5px;
}

.selectHairdresser {
  grid-area: selectHairdresser;
  @extend %selectAreaStyle;

  .select {
    color: $tealish;
    text-align: right;
    padding-right: 10px;
  }
}

.toggleDisabledHairdressers {
  letter-spacing: 0.2px;
  font-size: 0.813rem;
  color: $bluey-grey;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.selectDate {
  grid-area: selectDate;
  @extend %selectAreaStyle;
}

.rangePickersContainer {
  display: flex;
  align-items: center;

  span {
    margin: 0 5px;
  }
}

.prestations {
  grid-area: prestations;
  @extend %contentAreaStyle;
  padding: 30px;
  overflow-y: hidden;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "prestationTop"
    "prestationHeader"
    "prestationsList";
  padding-top: 15px;
}

.prestationHeader {
  @extend %headerText;
  grid-area: prestationHeader;
  display: flex;
  flex: 1;
  // box-shadow: 0px -10px 10px 25px $white;
  z-index: 1;
}

.amount {
  font-family: Montserrat-Bold;
  color: $blush-pink;
  text-align: center;
  flex: 1;
}

.flex {
  flex: 1;
}

.prestationsList {
  grid-area: prestationsList;
  overflow-y: scroll;
}

.footerMobile {
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(171deg, #06038d, #00a481);
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 15px 15px 15px;
  color: $white;
  font-family: Montserrat-Bold;
  letter-spacing: 0.2px;
}

// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-bottom)) {
  .footerMobile {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    padding: 20px 15px calc(var(--safe-area-inset-bottom) + 15px) 15px;
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-bottom)) {
  .footerMobile {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding: 20px 15px calc(var(--safe-area-inset-bottom) + 15px) 15px;
  }
}

.prestationTop {
  font-family: Montserrat-Bold;
  grid-area: prestationTop;
  display: flex;
  align-items: center;
  // box-shadow: 0px -10px 10px 25px $white;
  z-index: 1;
}

.smallSpan {
  font-size: 0.7em;
  color: grey;
  flex: 1;
  text-align: center;
}

.smallSpanMargin {
  @extend .smallSpan;
}

.topFooterMobile {
  color: grey;
  padding: 5px;
  font-size: 0.65em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.CAMargin {
  margin-right: 10px;
}
