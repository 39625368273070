@import "./fonts.scss";
@import "./variables.scss";
@import "./mixins.scss";
@import "./button.scss";
@import "./inputs.scss";
@import "./modal.scss";
@import "./agenda.scss";
@import "./mobileList.scss";
@import "./payment.scss";
@import "./rating.scss";
@import "./packages.scss";
@import "./account.scss";

html {
  -ms-overflow-style: none;
}

body {
  font-family: Montserrat-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 1rem;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

*:focus {
  outline-color: $tealish;
}

a {
  text-decoration: none;
}

input[disabled] {
  opacity: 1;
  -webkit-text-fill-color: currentColor;
}

input {
  font-family: Montserrat-Regular;
}

.logo-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $logo-color;
}

.color-error {
  color: $blush-pink !important;
}

.color-error span {
  color: $blush-pink !important;
}

div.list-separator {
  border: solid 1px $very-light-blue;
}

.page-container-mobile {
  position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  height: 100vh;
  width: 100vw;
}

.page-container-inner-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.page-header-mobile {
  padding-top: 35px;
  padding-bottom: 10px;
  background-image: linear-gradient(250deg, #06038d, #06038d);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-top)) {
  .page-header-mobile {
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 35px);
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-top)) {
  .page-header-mobile {
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 35px);
  }
}

.page-header-menu-bar-mobile {
  position: relative;
  text-align: center;
  min-height: 25px;
  margin-bottom: 10px;
}

.page-header-mobile.dense {
  padding-bottom: 0px;
}

.page-header-menu-bar-mobile.oneline {
  margin-bottom: unset;
}

.page-header-menu-bar-left-mobile {
  position: absolute;
  left: 13px;
}

.page-header-menu-bar-center-mobile {
  color: $white;
  font-family: Montserrat-Bold;
  font-size: 1rem;
  max-width: calc(100vw - 70px);
  margin: auto;
}

.page-header-menu-bar-right-mobile {
  position: absolute;
  right: 13px;
  top: 0;
}

.page-content-mobile {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ui_warning-box {
  position: relative;
  width: 100%;
  border-radius: 7px;
  text-align: center;
  background-color: rgba($blush-pink, 0.13);
  margin-top: 10px;
}

.ui_warning-box_icon {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $blush-pink;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -10px;
  margin: auto;
}

.ui_warning-box_text {
  display: block;
  padding: 0 12px 12px 12px;
  color: $blush-pink;
}

.modal-confirmation-message {
  text-align: center;
  color: $bluey-grey;
  font-size: 1.13rem;
}

div.snackbar-container {
  padding-top: 15px;
}

// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-top)) {
  .div.snackbar-container {
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top, 0) + 15px);
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-top)) {
  .div.snackbar-container {
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top, 0) + 15px);
  }
}

div.snackbar-container-error {
  background-color: $blush-pink !important;
}

div.snackbar-container-success {
  background-color: $tealish !important;
}

.snackbar-message {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.snackbar-icon {
  min-width: 26px;
  height: 26px;
  border-radius: 13px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: rgba(256, 256, 256, 0.3);
}

span.le-ciseau-title {
  font-family: Montserrat-Bold;
  color: $dark;
}

span.le-ciseau-title-extra {
  color: $light-blue-grey;
  padding-left: 5px;
}

span.le-ciseau-title.le-ciseau-title--desktop,
span.le-ciseau-title-extra.le-ciseau-title-extra--desktop {
  font-size: 1.363rem;
}

.ui-space_between-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ui-space_between-line.disabled {
  color: $dark;
  opacity: 0.4;
}

.ui-no-margin {
  margin: 0;
}

.ui-capitalize {
  text-transform: capitalize;
}

.ui-loader-centered {
  position: fixed;
  top: 45%;
  left: 45%;
}

.fullwidth {
  width: 100%;
}

.no-shrink {
  flex-shrink: 0;
}

.clickable {
  cursor: pointer;
}

.flex {
  display: flex;
}

.large-clickable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.large-clickable-container > div {
  position: relative;
}

.large-clickable {
  position: absolute;
  display: inline;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}

.page-header-mobile-iframe {
  background-image: unset;
  background-color: #ee7c56;
  padding-top: 10px;

  .MuiGrid-direction-xs-column.MuiGrid-align-items-xs-center {
    flex-direction: row;
  }

  div.calendar_day_picker-window {
    padding-top: 0px;
    padding-bottom: 8px;
  }

  span.calendar_day_picker-day-number {
    margin-top: -1px;
  }
}
