@import "styles/variables.scss";

$header-height: 35px;
$ruler-width: 45px;
$column-width--day-mode: 85px;
$column-width--week-mode: 48px;
$cell-height: 25px;
$timestep-line-grey: #9797972e;
$week-days-header-height: 40px;

.calendar-outer-container {
  min-width: fit-content;
  min-height: 100%;
}

.calendar-loader {
  position: fixed;
  z-index: 110;
  top: 50%;
  left: 50%;
}

.calendar_timegrid-container--week-mode {
  // display: table; // Fix Header width on EDGE but breaks width when not enough Hairdressers to display

  .calendar-week-days-header {
    height: $week-days-header-height;
    box-shadow: 0 2px 6px 0 rgba(37, 39, 52, 0.11);
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 101;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: $ruler-width;

    .calendar-week-days-header-item {
      opacity: 0.3;
      font-family: Montserrat-Bold;
      letter-spacing: 0.74px;
      font-size: 0.75rem;

      &.calendar-week-days-header-item--selected {
        color: $logo-color;
        opacity: 1;
        display: flex;
        align-items: center;

        .calendar-week-days-header-selected-item--extra {
          width: 14px;
          height: 14px;
          border-radius: 7px;
          background-image: linear-gradient(225deg, #06038d, #06038d);
          margin-right: 7px;
        }
      }
    }
  }

  .calendar_header_item {
    top: $week-days-header-height;
  }
}

.calendar-table-container {
  display: table;
}

.calendar_ruler-container {
  position: sticky;
  padding-top: calc(#{$header-height} - (#{$cell-height} / 2));
  left: 0;
  display: table-cell;
  min-width: $ruler-width;
  background-color: $white;
  z-index: 100;
  border-right: 1px solid $border-grey;

  &.calendar_ruler-container--with-icon {
    padding-top: calc(55px - (#{$cell-height} / 2));
    box-shadow: 0 2px 9px 0 rgba(37, 39, 52, 0.19);
  }
}

.calendar_ruler_item {
  min-height: $cell-height;
  position: relative;
}

.calendar_ruler_item-hour {
  font-size: 0.88rem;
  position: absolute;
  left: 6px;
  top: 2px;
}

.calendar_ruler_item-minutes {
  font-size: 0.63rem;
  position: absolute;
  right: 6px;
  top: 5px;
  color: $light-blue-grey;
}

.calendar_timegrid-tablecell-container {
  display: table-cell;
  position: relative;
  width: 100%;
}

.calendar_timegrid-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: calc(#{$header-height} - 1px);
    left: 0;
    right: 0;
    bottom: 0;
    background-image: repeating-linear-gradient(
      to bottom,
      $timestep-line-grey 0,
      $timestep-line-grey 1px,
      $very-light-grey 1px,
      $very-light-grey $cell-height
    );
  }

  &.calendar_timegrid-container--with-icon {
    &::before {
      top: calc(55px - 1px);
    }
  }
}

.calendar_header_item {
  position: sticky;
  top: 0;
  min-height: $header-height;
  text-align: center;
  border-bottom: 1px solid $border-grey;
  background-color: $white;
  z-index: 50;
  font-size: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}

.calendar_header_item--with-icon {
  height: 55px;
  font-size: 1rem;
}

.calendar_header_item--with-icon_title {
  margin-left: 10px;
}

.calendar_column-container {
  height: 100%;
  flex-grow: 1;

  &.calendar_column-container--week-mode,
  &.calendar_column-container--with-icon {
    .calendar_header_item {
      background-color: $very-light-grey;
    }
  }

  &:not(:last-child) {
    .calendar_column-events-container {
      border-right: 1px solid $border-grey;
    }
  }

  &.calendar_column-container--disabled {
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 1;

    & > .calendar_header_item span {
      display: flex;
      flex-direction: column;

      &::after {
        content: "(Désactivé)";
        font-size: 0.6rem;
        color: $dark;
        opacity: 0.5;
      }
    }
  }

  &.calendar_first-resource-of-day_container {
    &:not(:first-child) {
      .calendar_column-events-container,
      .calendar_header_item {
        border-left: solid 1px #c2c5d0;
      }
    }
  }

  &.calendar_last-resource-of-day_container {
    .calendar_column-events-container {
      border-right: unset;
    }
  }
}

.calendar_column-events-container {
  height: calc(100% - #{$header-height});
  min-width: $column-width--day-mode;
  position: relative;

  &.calendar_column-events-container--with-icon {
    height: calc(100% - 55px);
  }

  &.calendar_column-events-container--week-mode {
    min-width: $column-width--week-mode;
  }
}

.calendar_event-item {
  position: absolute;
  left: 0;
  min-width: calc(100% - 15px);
  max-width: 100%;
  width: 100%;
  min-height: $cell-height;
  border-radius: 0px;
}

.calendar_month_picker-label-mobile {
  text-transform: capitalize;
  color: $white;
  font-family: Montserrat-Bold;
  font-size: 1rem;
  text-align: center;
  position: relative;
}

.calendar_month_picker-arrow {
  margin-left: 12px;
}

.calendar_month_picker-input-mobile {
  position: absolute;
  opacity: 0;
  left: 0;
  width: 100%;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
  }
}

.input-date-container {
  position: relative;
}

.calendar_filter_selector-mobile {
  text-transform: capitalize;
  color: $white;
  font-family: Montserrat-Bold;
  font-size: 0.88rem;
  text-align: center;
  cursor: pointer;
}

div.calendar_day_picker-window {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding-top: 18px;
  padding-bottom: 15px;
}

div.agenda-page-header {
  position: relative;
}

div.agenda-page-header::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  background-color: $white;
}

div.agenda-page-header::before {
  content: "";
  position: absolute;
  left: 0;
  width: 43px;
  bottom: -25px;
  height: 25px;
  z-index: 101;
  background-image: linear-gradient(
    to bottom,
    $white 0%,
    rgba(255, 255, 255, 0.9) 25%,
    rgba(255, 255, 255, 0.75) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.calendar_day_picker-window::-webkit-scrollbar {
  display: none;
}

span.calendar_day_picker-day-name {
  text-transform: uppercase;
  font-size: 0.88rem;
  text-align: center;
  color: $white;
}

span.calendar_day_picker-day-number {
  margin-top: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  opacity: 0.6;
  color: $very-light-grey;
}

.calendar_day_picker-day-group {
  position: relative;
  cursor: pointer;
}

.calendar_day_picker-day-group.selected::after {
  content: "";
  position: absolute;
  top: -10px;
  bottom: -6px;
  left: -10px;
  right: -10px;
  background-color: $white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.2);
}

.calendar_day_picker-day-group.selected span {
  opacity: 1;
  color: $tealish;
  z-index: 1;
}
