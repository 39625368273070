@import "styles/variables.scss";
@import "styles/mixins.scss";

.emptyResultContainer {
  padding: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.resultContainer {
  @include mobile {
    padding: 0px;
    padding-bottom: 25px;
  }

  @include desktop {
    padding: 0px;
    padding-top: 0;
  }
}

@include mobile {
  .listContainer {
    margin: 0 !important;
  }
}

.title {
  padding: 25px 15px;
  display: block;
  text-align: center;
  font-family: Montserrat-Bold;
  letter-spacing: 0.2px;
  color: $dark;
}
