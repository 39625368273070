.plusButton {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1px;
  margin-bottom: 10px;
  margin-right: 10px;
}

button.hairdresserBtn,
button.hairdresserBtn.button-desktop.button-desktop--primary {
  font-family: "Montserrat-Bold";
  color: white;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 0px;
  margin-top: 0px;
  padding: 5px;
}
