@import "styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;

  @include desktop {
    &:hover {
      cursor: pointer;
    }
  }

  &.withUnderline {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 0;
      border: solid 0.5px $very-light-blue;

      @include desktop {
        right: 15px;
      }
    }

    &.fullWidthUnderline {
      &::after {
        left: 0;
        right: 0;
      }
    }
  }
}

.variant {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.smallFlexContainer {
  display: flex;
  flex: 0.1;
}

.primaryContentContainer {
  display: flex;
  flex: 0.5;
  @include mobile {
    font-size: 0.8em;
    min-width: 100px;
  }

  .checkbox {
    align-self: baseline;
    padding-top: 2px;

    @include desktop {
      padding-left: 0;
    }
  }
}

.titleAndVariant {
  display: flex;
  flex-direction: column;

  .title {
    color: $dark;
    font-size: 0.9em;
  }
  .titleDisabled {
    font-size: 1rem;
    color: $light-grey;
    text-decoration: line-through;
  }
}

.secondaryContentContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
  @include mobile {
    flex: 0.2;
    font-size: 0.8em;
  }
}

.priceOutterContainer {
  flex-shrink: 0;
  text-align: right;
  @include mobile {
    flex: 1;
  }

  .title {
    font-family: Montserrat-Bold;
    text-transform: uppercase;
    font-size: 0.69rem;
    letter-spacing: 0.68px;
    color: $bluey-grey;
    flex-shrink: 0;
    @include mobile {
      font-size: 0.7em;
    }
  }

  .priceContainer {
    text-align: right;
    margin-top: 5px;
    font-family: Montserrat-Bold;
  }
}

.nextIcon {
  margin-left: 5px;
}

.overContent {
  width: 100%;
}

.overContentDisabled {
  width: 100%;
  opacity: 0.5;
}

.discreetLabel {
  > span:last-child {
    font-size: 0.8em;
    color: gray;
    max-width: 50%;
  }
}

.warningIcon {
  background-color: rgb(255, 128, 134);
  width: 17px;
  height: 17px;
  border-radius: 8.5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding-left: 1px;
}

.smallText {
  font-size: 0.8em;
}

.ternaryContainer {
  @include mobile {
    flex: 0.2;
    font-size: 0.8em;
    margin-left: 10px;
  }
}

.quaternaryContainer {
  @include mobile {
    flex: 0.2;
  }
}

.variant {
  @include mobile {
    font-size: 0.8em;
  }
}
