@import "../../../styles/variables.scss";

.contentContainer {
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;
}

.backdrop {
  width: 100%;
  height: 100%;
  background-color: $very-light-grey !important;
}
