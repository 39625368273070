@import "../../../styles/mixins.scss";

.contentContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  @include desktop {
    max-width: 530px;
  }
}

.selectedMenuItem:not(:hover) {
  background-color: unset !important;
}
