@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.hairdresserFilterContainer {
  display: flex;
  overflow-x: scroll;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  cursor: pointer;
  min-height: 50px;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  > div {
    position: relative;
    padding: 15px 25px;
    flex-shrink: 0;
    flex-grow: 1;
    text-align: center;
  }

  span {
    font-size: 0.94rem;
  }
}

.bookingsContainer {
  @include desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    margin: auto;
  }
}

.underline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  border-radius: 2px;
}

.noBookings {
  display: inline-block;
  margin: auto;
  margin-top: 40vh;
  text-align: center;
  color: $light-grey;

  @include mobile {
    width: 100%;
  }
}
