@import "../../styles/variables.scss";

.desktopToolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 11px 22px;
  box-shadow: 0 2px 6px 0 rgba(37, 39, 52, 0.11);
  position: sticky;
  top: 0px;
  z-index: 101;
  flex-shrink: 0;
  min-height: 68px;
}
