@import "../../../styles/variables.scss";

.desktopRepeatInput {
  margin-left: -5px;
}

.repeatLabel {
  margin-left: 15px;
}

.desktopDaySelection {
  margin-top: 20px;
}

.checkoxControl {
  margin-right: 26px;

  input[disabled] {
    opacity: 0;
  }
}

.selectedLabel {
  color: $tealish;
}

.desktopFollowingWeeksInput {
  composes: desktopRepeatInput desktopDaySelection;

  label.desktopWeeksSelectLabel {
    transform: unset;
    font-family: Montserrat-Regular;
    font-size: 1rem;
    color: $dark;
    top: unset;
    bottom: 8px;
  }
}

.disabled {
  color: $light-blue-grey;
}

.desktopWeeksSelect {
  text-align: right;
}
