@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.headerContainer {
  @include mobile {
    margin: 15px;
  }

  @include desktop {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    box-shadow: 0px 10px 5px 5px #fafafa;
    z-index: 10;
    margin-bottom: 15px;
    width: 100%;
  }

  > div {
    display: flex;
    flex-shrink: 0;
  }
}

.mainLine {
  justify-content: space-between;
  font-size: 1.13rem;
}

.title {
  font-family: Montserrat-Bold;
  color: $dark;
  @include desktop {
    font-size: 1.318rem;
  }
}

.ratingContainer {
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
}

.stats {
  opacity: 0.4;
  font-weight: 500;
  color: $rating-extra-grey;
}

.ratingCountWithStars {
  @include mobile {
    margin-top: 10px;
  }

  span {
    margin-left: 5px;
  }
  @include desktop {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

.rateNumber {
  @include desktop {
    font-size: 1.318rem;
  }
}
