@import "styles/variables.scss";
@import "styles/mixins.scss";

%desktopContainerStyle {
  @include desktop {
    padding: 20px;
    border-radius: 4px;
    border: solid 1px $very-light-blue;
    background-color: $white;
  }
}

.desktopContainer {
  height: 100%;
  width: 90%;
  min-width: 800px;
  max-width: 1400px;
  margin: auto;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-areas:
    "filters filters"
    "balance balance"
    "prestations payouts";
}

.balanceContainer {
  @include mobile {
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  @extend %desktopContainerStyle;
  @include desktop {
    grid-area: balance;
  }
}

.title {
  font-family: Montserrat-Bold;
  color: $dark;

  @include mobile {
    letter-spacing: 0.2px;
  }

  @include desktop {
    font-size: 1.313rem;
  }
}

.prestationsTitle {
  @extend .title;

  padding: 30px 10px 25px 20px;
}

.amount {
  composes: title;

  color: $blush-pink;
  @include mobile {
    float: right;
  }
  @include desktop {
    margin-left: 5px;
  }
}

%desktopListStyle {
  @extend %desktopContainerStyle;

  @include desktop {
    overflow-y: scroll;

    .title {
      position: sticky;
      top: 0;
      display: inline-block;
      width: 100%;
      z-index: 1;
      background-color: $white;
    }
  }
}

.prestationListContainer {
  @extend %desktopListStyle;

  padding: 0px;
}

.payoutListContainer {
  @extend %desktopListStyle;

  padding: 0px;
}

span.tabIndicator {
  bottom: 10px;
}

.tabsFlexContainer {
  justify-content: space-around;
}

button.tabContainer {
  font-family: Montserrat-Bold;
  font-size: 1rem;
  letter-spacing: 0.2px;
  text-transform: none;
  color: $light-blue-grey;
  margin: 0 6px;
  padding: 6px 6px;

  > span > span {
    padding: 6px 0;
  }
}

.datePickerContainer {
  padding: 5px 0px;
  cursor: pointer;
  width: 100%;
  grid-area: filters;
  grid-row: 1;
}

.input {
  padding: 12px 25px 10px 15px;
}

.label {
  transform: translate(14px, 14px) scale(1);
  cursor: pointer;
}
