@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  $border-radius: 4px;
  display: flex;
  align-items: center;

  @include mobile {
    border-radius: $border-radius;
    margin: 0 10px 10px 10px;
    padding: 15px 20px 15px 15px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
    background-color: $white;
  }

  @include desktop {
    $border: solid 1px $very-light-blue;

    cursor: pointer;
    width: 100%;
    margin: 0 auto;
    padding: 25px 25px 20px 30px;
    border-left: $border;
    border-right: $border;
    background-color: $white;

    &:first-child {
      border-top: $border;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:last-child {
      border-bottom: $border;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.selected,
    &.disabled.selected {
      background-color: $very-light-blue20;
    }
  }

  &.disabled {
    $disabled-opacity: 0.5;

    background-color: $white50;
    > * {
      opacity: $disabled-opacity;
    }
  }
}

.content {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 50px;
  justify-content: space-between;
}

.name {
  font-size: 1.19rem;
}

.disabledText {
  font-size: 0.88rem;
  color: $bluey-grey;
}

.packages {
  font-size: 0.88rem;
  color: $bluey-grey;
  display: flex;

  &.warning {
    color: $warning-red;
  }

  .icon {
    width: 17px;
    height: 17px;
    background-color: $warning-red;
    border-radius: 8.5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
}

.inSelect {
  box-shadow: none;
  background: none;
  background-color: transparent;
}
