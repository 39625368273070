@import "./variables.scss";

@mixin mobile {
  @media (max-width:calc(#{$breakpoint-mobile} - 1px)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile}) {
    @content;
  }
}
