@import "../../../styles/variables.scss";

.menu-desktop,
.menu-desktop-logo {
  display: flex;
  align-items: center;
}

.menu-desktop {
  padding: 12px 24px 6px 16px;
  border-bottom: solid 1px $light-grey20;
  background-color: $white;
  justify-content: space-between;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  height: $desktop-menu-height;
  z-index: 102;
  box-shadow: 0 2px 6px 0 rgba(37, 39, 52, 0.11);

  &.no-shadow {
    box-shadow: unset;
  }
}

.menu-desktop-logo {
  div:first-child {
    margin-right: 10px;
  }
}

.menu-desktop-nav {
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
}

.menu-desktop-salon-selector {
  min-width: 185px;
  flex-shrink: 0;
  margin-left: 30px;
}

.logoutLink {
  color: $blush_pink !important;
}

.menu-desktop-redirect {
  min-width: 185px;
  flex-shrink: 0;
  margin-left: 30px;
  font-size: 0.8em;
  a {
    text-decoration: none;
    color: #a8a9ae;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
  }
}

.menu-desktop-redirect-span {
  font-family: Montserrat-Bold;
  letter-spacing: 0.74px;
  display: inline-block;
  vertical-align: middle;
}

.menu-desktop-iframe {
  left: 0;
  height: 100%;
  width: 230px;
  flex-direction: column;
  color: white;
  background-color: #ee7c56;
  grid-area: menu;
  padding-left: 0;
  padding-right: 0;
  justify-content: flex-start;
  overflow: scroll;
}
.menu-desktop-nav-iframe {
  flex-direction: column;
  background-color: #ee7c56;
}

.container-no-iframe {
}

.container-iframe {
  display: grid;
  grid-template-areas: "menu container";
  grid-template-columns: 230px 1fr;
  max-height: 100vh;
  overflow: scroll;
}

.container-iframe-no-toolbar {
  display: grid;
  grid-template-areas: "menu content";
  grid-template-columns: 230px 1fr;
  max-height: 100vh;
  overflow: scroll;
}

.grid-toolbar {
  grid-area: container;
  display: grid;
  grid-template-areas:
    "toolbar toolbar"
    "content content";
  grid-template-rows: 70px 1fr;
  overflow: scroll;
  flex-grow: 1;
  height: 100vh;
}

.simple-toolbar {
  grid-area: container;
  display: grid;
  flex-grow: 1;
  grid-template-areas:
    "toolbar toolbar"
    "content content";
  grid-template-rows: 68px 1fr;
  overflow: scroll;
  flex-direction: column;
  width: 100vw;
}

.mkpTitle {
  font-size: 0.9em;
  border-bottom: 1px solid white;
  display: block;
  padding-bottom: 20px;
  margin-bottom: 15px;
}

div.sublinks-mobile-iframe {
  background: #f3f3f3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 280px;
  font-size: 1.3em;
  padding-top: 40px;
  padding-left: 40px;
  a:not(:last-child)::after {
    content: none;
  }
}
