.term-agreement-form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.term-agreement-chekbox-container {
  label {
    align-items: flex-start;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.term-agreement-chekbox {
  padding-top: 3px;
}

.term-agreement-chekbox-error {
  margin-top: 0;
  margin-left: 25px;
}
