@import "./variables.scss";
@import "./mixins.scss";

.button-container {
  margin: 25px 0;
  width: 100%;

  .button {
    min-width: 180px;
    height: 50px;
    box-shadow: none;
    background-image: $backgroud-gradient;
  }

  span {
    color: $white;
    font-family: Montserrat-Bold;
    letter-spacing: 1px;
    padding: 0 35px;
  }

  .button-text span {
    color: $dark;
  }
}

.button-container-mobile {
  .button {
    width: 85%;
  }
}

.button-container-fullWidth {
  .button {
    width: 100%;
  }
}

div.button-container-warning .button {
  background-image: unset;
  background-color: $blush-pink;
}

button.button-text {
  padding: 0;
}

button.fab_button-mobile {
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-image: $backgroud-gradient;
  z-index: 1;
}

// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-bottom)) {
  button.fab_button-mobile {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    bottom: calc(var(--safe-area-inset-bottom) + 15px);
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-bottom)) {
  button.fab_button-mobile {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    bottom: calc(var(--safe-area-inset-bottom) + 15px);
  }
}
