@import "styles/variables.scss";
@import "styles/mixins.scss";

$container-background-color: #fbfbfc;

@include desktop {
  .contentContainer {
    margin: 0 20px;
    padding: 20px 15px;
    height: 45vh;
    min-width: 600px;
    max-width: 820px;
    width: 40vw;
    overflow-y: scroll;
    border-radius: 4px;
    border: solid 1px $very-light-blue;
    background-color: $container-background-color;
  }

  .packagesListContainer {
    margin-bottom: 30px;
  }
}

.selectAllToggler {
  display: flex;
  align-items: center;

  @include mobile {
    justify-content: center;
    margin-top: 15px;
  }

  @include desktop {
    padding-left: 30px;
    padding-bottom: 10px;
  }
}

.categoryName {
  font-family: Montserrat-Bold;
  font-size: 1.13rem;
  color: $dark;
  margin-top: 10px;
  margin-bottom: 20px;

  @include mobile {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &:first-child {
    margin-top: 30px;
    @include desktop {
      margin-top: 40px;
    }
  }

  @include desktop {
    margin-top: 40px;
  }
}
