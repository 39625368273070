@import "./variables.scss";

div.input-group:not(:first-child) {
  margin-top: 25px;
}

div.input-group-inline div.input-group {
  margin-top: unset;
}

div.input-group-inline div.input-group:not(:first-child) {
  margin-left: 30px;
}

div.input-group-inline-item-grow {
  flex-grow: 1;
}

label.input_label {
  text-transform: uppercase;
  font-family: Montserrat-Bold;
  font-size: 0.69rem;
  letter-spacing: 0.7px;
  color: $bluey-grey !important;
  transform: scale(1);
}

label.input_label_inline {
  font-size: 1rem;
  letter-spacing: 0.2px;
  color: $dark !important;
  top: -16px;
}

div.input-group {
  position: relative;
}

div.input-group textarea {
  padding-right: 10px;
}

div.input_input > input[type="text"],
div.input_input > div.fake-input {
  display: block;
  text-overflow: ellipsis;
  padding-right: 5px;
  min-height: 19px;
}

label + div.input_input_inline {
  margin-top: unset;
}

div.input_input_inline input,
div.input_input_inline div.fake-input {
  font-family: Montserrat-Bold;
  font-size: 1rem;
  letter-spacing: 0.2px;
  text-align: right;
  padding-right: 23px;
  color: $dark;
}

div.input-group div.input_input::before {
  border-bottom-style: solid;
  border-bottom-color: $very-light-blue;
}

div.input-group > div.input_time {
  width: 130px;
}

.input_date-container {
  position: relative;
}

p.input_date_helper_text {
  position: absolute;
  top: 16px;
  color: inherit;
  font-size: inherit;
}

div.input_date input {
  opacity: 0 !important;
  width: 100%;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
  }
}

.input_date-picker-arrow {
  position: absolute;
  right: 0;
  top: 28px;
}

.select-modal_content-wrapper_mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
}

span.label-checkbox {
  font-family: Montserrat-Regular;
  font-weight: 500;
  font-size: 1rem;
  color: $dark;
  padding-left: 6px;
  width: 100%;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

span.label-checkbox.selected {
  color: $tealish;
}

span.list-underline::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid $very-light-blue;
}

.wide-list-underline {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid $very-light-blue;
}

div.input_input-icon {
  position: absolute;
  top: 20px;
  right: 0;
}

div.input_input-icon.inline {
  position: absolute;
  top: 7px;
  right: 0;
}

.input-password-container {
  position: relative;
}

.input-password_visibility-icon {
  color: $tealish;
  position: absolute;
  right: 0;
  top: 20px;
}

.input-switch-container {
  position: relative;
  display: inline-block;
  height: 26px;
  min-width: 46px;

  input[disabled] {
    opacity: 0;
  }
}

.input-switch-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.input-switch-container span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9ecf0;
  transition: 0.4s;
  border-radius: 13px;
}

.input-switch-container--disabled span {
  cursor: unset;
}

.input-switch-container span:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.25);
}

.input-switch-container input:checked + span {
  background-image: $backgroud-gradient;
}

.input-switch-container input:checked + span:before {
  transform: translateX(20px);
}

.mobile_picker-container {
  position: relative;
  margin-bottom: 15px;
}

.mobile_picker-container input {
  opacity: 0;
}

.mobile_picker-container input[disabled] {
  opacity: 0;
}

.mobile_picker-label {
  position: absolute;
  left: 0;
}

.mobile_picker-input {
  text-align: right;
}

.mobile_picker-display_value {
  position: absolute;
  right: 20px;
}

.mobile_picker-container-disabled {
  opacity: 0.4;
}

textarea::placeholder {
  opacity: 1;
  font-size: 1rem;
  color: $light-blue-grey;
  padding-left: 5px;
}

div.mobile-select-confirmation-helper {
  margin-top: -10px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  font-size: 0.813rem;
  letter-spacing: 0.2px;
  text-align: center;
  color: $bluey-grey;
}

span.form-input-error {
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 15px;
  color: $blush-pink;
  font-size: 0.813rem;
}

// Wheel Picker

.wheel-picker_container {
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.wheel-picker_input-container {
  position: relative;
}

.wheel-picker_input-container.floating::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12.5px 20px 12.5px;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) $white
    rgba(255, 255, 255, 0);
  z-index: 120;
  left: 50px;
}

.wheel-picker_wheels_container {
  position: relative;
  display: flex;
  justify-content: center;
}

.wheel-picker_wheels_container.floating {
  position: absolute;
  background-color: $white;
  top: 68px;
  border-radius: 5px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
  z-index: 110;
  transition-property: height, opacity, visibility;
  transition-duration: 0.25s;
}

.right-floating-wheel-picker .wheel-picker_wheels_container.floating {
  right: 0;
}

.wheel-picker_wheel_outer_container {
  position: relative;
}

.wheel-ghost-items-container {
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0;
  height: 100%;
  overflow-y: hidden;
}

.wheel-picker_wheel_container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  min-width: 50px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.wheel-picker_wheel_container::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.wheel-picker_wheel_container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  will-change: font-size, opacity;
}

.wheel-picker_selection-lines {
  position: absolute;
  width: 100%;
  border-top: 1px solid $very-light-blue;
  border-bottom: 1px solid $very-light-blue;
  pointer-events: none;
  box-sizing: border-box;
}

.wheel-picker_separator {
  display: flex;
  align-items: center;
  font-size: 1.375rem;
}

.time-picker_input-container {
  position: relative;
}
