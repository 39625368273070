.salon-form-access-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.salon-access-name {
  flex-grow: 1;
}

.salon-preview-modal-container {
  padding-bottom: 0;
}

.salon-preview-container {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;
}

.salon-preview-close {
  position: absolute;
  top: 40px;
  left: 15px;
  filter: drop-shadow(0 0 2px black);
}

.salon-preview-background {
  height: 245px;
  object-fit: cover;
}

.salon-preview-logo {
  position: absolute;
  top: 208.5px;
  right: 15px;
  width: 75px;
  height: 75px;
  border-radius: 37.5px;
  box-shadow: 0 4px 10px 0 rgba(44, 68, 102, 0.15);
}

.salon-preview-name {
  display: inline-block;
  font-family: Montserrat-Bold;
  font-size: 1.38rem;
  max-width: calc(100% - 100px);
}

.salon-preview-description {
  display: inline-block;
  margin-top: 20px;
  line-height: 1.56;
  letter-spacing: 0.2px;
}

.salon-preview-address-container {
  display: flex;
  position: relative;
  margin-top: 30px;
  align-items: center;
}

.salon-preview-address-container::before {
  content: "";
  position: absolute;
  left: -15px;
  top: -18px;
  width: 100vw;
  border: 0.5px solid $very-light-blue;
}

.salon-preview-address-container svg {
  height: 18px;
  width: 18px;
}

.salon-preview-address {
  margin-left: 10px;
  letter-spacing: 0.2px;
}

label.hairdresser-form-radio-input {
  min-width: 135px;
}

.salon-map {
  left: -15px;
  width: 100vw;
  height: 290px;
  margin-top: 15px;
}

.fullScreen-dialog {
  max-width: 1080px;
}

.hairdresser-form-radio-input {
  .Mui-disabled {
    padding: 0;
    margin: 6px;
  }
}
