.agenda_calendar-container {
  position: relative;
  width: 100%;
  height: 100%;
}

div.agenda-page-content {
  background-color: $white;
}

.agenda_slot {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
}

.agenda_slot-free {
  z-index: 10;
  border-width: 1px;
  border-style: solid;
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $white;
  overflow: hidden;

  position: absolute;
  width: 30px;
  left: 10px;
  border-width: 4px;
  border-radius: 500px;

  &:hover {
    cursor: pointer;
  }

  span {
    font-family: Montserrat-Bold;
    text-align: center;
    font-size: 0.95rem;
    max-width: 100%;
  }
}

.agenda_slot-creating {
  color: $grey;
  border-width: 2px;
  border-style: dashed;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  border-color: $grey80;
  background-image: repeating-linear-gradient(
    -45deg,
    $white50,
    $white50 7px,
    $grey20 7px 9px
  );

  span {
    font-family: Montserrat-Bold;
    text-align: center;
    font-size: 0.75rem;
    max-width: 90%;
  }

  &:hover {
    cursor: grabbing;
  }
}

.agenda_slot-last_minute {
  z-index: 18;
  pointer-events: auto;
  border-width: 2px;
  border-top-style: inset;
  border-bottom-style: inset;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0.5;
  color: $white50;
  width: 100%;

  span {
    font-family: Montserrat-Bold;
    text-align: center;
    font-size: 0.75rem;
    max-width: 90%;
  }
}

.agenda_slot-unavailable {
  z-index: 20;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0;
  border-radius: 0px;
  background-image: repeating-linear-gradient(
    to bottom,
    #d2d3d6 0,
    #d2d3d6 1px,
    #cacbcf 1px,
    #cacbcf 25px
  );

  span {
    font-family: Montserrat-Bold;
    text-align: center;
    font-size: 0.75rem;
    max-width: 90%;
  }
}

.agenda_slot-booked {
  z-index: 21;
  padding: 4px 10px;
  background-image: linear-gradient(208deg, #06038d, #06038d);
  font-family: Montserrat-Bold;
  font-size: 0.75rem;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: calc(100% - 15px);
  max-width: calc(100% - 9px);
  width: 100%;
  position: absolute;
  left: 5px;

  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  span {
    display: block;
  }
}

.agenda_slot-booked-header {
  display: flex;
  flex-direction: row;
}

.agenda_slot-booked-sub-header {
  flex: auto;
}

span.agenda_slot-booked-slotrange {
  font-family: Montserrat-Regular;
  font-size: 0.57rem;
}

span.agenda_slot-booked-package {
  font-family: Montserrat-Regular;
  font-size: 0.6rem;
}

span.agenda_slot-booked-discount {
  font-family: Montserrat-Regular;
  font-size: 0.6rem;
  float: right;
  margin-top: -14px;
}

.agenda-filters-form-mobile {
  margin-top: 20px;
}

.booked-slot-form_package-label {
  display: block;
}

.booked-slot-form_package-name,
.booked-slot-form_package-price {
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 10px;
}

.booked-slot-form_package-name {
  color: $dark;
  max-width: 80%;
}

.booked-slot-form_package-price {
  float: right;
  font-family: Montserrat-Bold;
}

.booked-slot-form_package-price_full {
  font-size: 0.88rem;
  color: $light-blue-grey;
  text-decoration: line-through;
  margin-right: 5px;
}

.booked-slot-form_package-price_reduced {
  color: $blush-pink;
}

.booked-slot-form_package-price-variant {
  margin-bottom: 10px;
}

.booked-slot-form_package-description {
  font-size: 0.88rem;
  color: $bluey-grey;
}

.agenda-repeat_slot-day_line-container {
  margin-right: 10px;
}

.agenda-repeat_slot-day_line-container label {
  width: calc(100% - 16px);
  margin-right: 0;
  border-bottom: 1px solid $very-light-blue;
}

.repeat-slot-weeks-arrow {
  top: 13px;
}

.agenda-month-picker {
  max-width: 90%;
  margin: auto;
}

.booking-modal-ref {
  color: $light-grey;
  font-size: 0.94rem;
  margin-top: 7px;
  user-select: text;
}

.agenda_slot.agenda_slot-free span {
  z-index: 19;
  transform: rotate(0.75turn);
  margin-left: 9px;
  margin-top: 12px;
  line-height: 9px;
  font-size: 0.65em;
  &:nth-child(n + 2) {
    margin-top: 50px;
  }
}

.agenda_slot.agenda_slot-free span.agenda_slot_discount {
  transform: rotate(0.75turn);
  margin-left: 23px;
  margin-top: 10px;
  line-height: unset;
  &:nth-child(n + 2) {
    margin-top: 30px;
  }
  font-size: 1em;
}

button.flexy-background {
  background-color: white;
  color: #06038d;
  border-color: #06038d;

  span {
    svg {
      display: inline-block;
      vertical-align: middle;
      width: 55px;
    }
  }
}

a.flexy-background-rounded {
  @extend button.flexy-background;
  border-radius: 500px;
  border: 1px solid #06038d;
  padding: 10px 20px;
  font-size: 1.1em;
  color: white;
  background-color: #06038d;
}

.discover-flexy-modal {
  max-height: 100%;
  padding-left: 25px;
  padding-right: 25px;
  overflow-y: scroll;

  .large-clickable-container {
    justify-content: flex-end;
    margin-top: 40px;
    margin-bottom: -16px;
  }

  a.flexy-background-rounded {
    font-size: 0.7em;
  }
}

.discover-flexy-body {
  padding-left: 75px;
  padding-right: 75px;
  @include mobile {
    padding-left: 0px;
    padding-right: 0px;
  }
  padding-bottom: 30px;
  text-align: center;
  font-size: 0.8em;
}

.flexy-bold {
  font-family: "Montserrat-Bold";
}

.menu-mobile-scrollable-container {
  max-height: 60%;
  overflow-y: scroll;
}

.agenda-inSalonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.agenda-inSalon {
  margin-left: 5px;
  color: $white;
  font-weight: bold;
  font-size: 0.9em;
  @include mobile {
    font-size: 0.8em;
  }
}

.agenda_availability {
  width: 30px;
  border-radius: 500px;
  border-width: 4px;
  position: absolute;
  left: 10px;
  & > div {
    font-family: "Montserrat-Bold";
    transform: rotate(0.75turn);
    top: 44%;
    position: relative;
    font-size: 0.9em;
    width: 150px;
  }
}

#no-padding-dialog {
  padding: 0;
}

#big-h2 {
  text-align: center;
  & > h2 {
    font-family: "Montserrat-Bold";
  }
}

#fuller-width-dialog {
  .MuiDialog-container {
    width: calc(100% - 16px);
    margin: 8px;
  }
  .MuiDialog-paperFullWidth {
    width: calc(100% - 16px);
  }
  .MuiDialog-paper {
    margin: 8px;
  }
}
