@import "../../../styles/variables.scss";

%paddingStyle {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.21);
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 10px;
  }
}

.desktopContainer {
  padding: 20px 50px 30px 50px;

  &.withPadding {
    @extend %paddingStyle;
  }

  &:not(:last-child) {
    border-bottom: solid 1px $very-light-blue;
  }
}

.mobileContainer {
  padding: 25px 15px;
  background-color: $white;
  @extend %paddingStyle;

  &.dense {
    padding: 15px 15px 10px 15px;
  }

  &.extended {
    flex-grow: 1;

    &:last-child {
      margin-bottom: unset;
    }
  }

  &.withSeparator {
    > div {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 25px;

        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: -15px;
          width: 100vw;
          border: solid 0.5px $very-light-blue;
        }
      }
    }
  }

  &.noPadding {
    padding: 0;
  }

  &.noBackground {
    background-color: unset;
    box-shadow: unset;
  }
}

:global(.salon-preview-container) .mobileContainer {
  padding-bottom: 0;
}
